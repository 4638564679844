<template>
  <div id='spaceBackground' class="space-background"></div>
</template>
  
<script>
  export default {
    data () {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    },
    mounted() {
      $('#spaceBackground').warpDrive({
        width: this.width,
        height: this.height,
      });
      $('#spaceBackground').find('canvas').css('background-color', 'transparent');
    }
  };
</script>
  
<style scoped>
    .space-background {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
</style>