<template>
  <div class="theme-switcher">

    <!-- <label for="theme-toggle">Темная тема:</label> -->
    <div class="theme-switch-wrapper">
      <input type="checkbox" id="theme-toggle" name="theme-switch" class="theme-switch__input" @change="toggleTheme" :checked="isDark">
      <label for="theme-toggle" class="theme-switch__label">
          <span class="theme-switch__icon">
            <!-- Солнце -->
            <svg class="icon icon-sun" xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path d="M375.7 19.7c-1.5-8-6.9-14.7-14.4-17.8s-16.1-2.2-22.8 2.4L256 61.1 173.5 4.2c-6.7-4.6-15.3-5.5-22.8-2.4s-12.9 9.8-14.4 17.8l-18.1 98.5L19.7 136.3c-8 1.5-14.7 6.9-17.8 14.4s-2.2 16.1 2.4 22.8L61.1 256 4.2 338.5c-4.6 6.7-5.5 15.3-2.4 22.8s9.8 13 17.8 14.4l98.5 18.1 18.1 98.5c1.5 8 6.9 14.7 14.4 17.8s16.1 2.2 22.8-2.4L256 450.9l82.5 56.9c6.7 4.6 15.3 5.5 22.8 2.4s12.9-9.8 14.4-17.8l18.1-98.5 98.5-18.1c8-1.5 14.7-6.9 17.8-14.4s2.2-16.1-2.4-22.8L450.9 256l56.9-82.5c4.6-6.7 5.5-15.3 2.4-22.8s-9.8-12.9-17.8-14.4l-98.5-18.1L375.7 19.7zM269.6 110l65.6-45.2 14.4 78.3c1.8 9.8 9.5 17.5 19.3 19.3l78.3 14.4L402 242.4c-5.7 8.2-5.7 19 0 27.2l45.2 65.6-78.3 14.4c-9.8 1.8-17.5 9.5-19.3 19.3l-14.4 78.3L269.6 402c-8.2-5.7-19-5.7-27.2 0l-65.6 45.2-14.4-78.3c-1.8-9.8-9.5-17.5-19.3-19.3L64.8 335.2 110 269.6c5.7-8.2 5.7-19 0-27.2L64.8 176.8l78.3-14.4c9.8-1.8 17.5-9.5 19.3-19.3l14.4-78.3L242.4 110c8.2 5.7 19 5.7 27.2 0zM256 368a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"/></svg>
            <!-- Луна -->
            <svg class="icon icon-moon" xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path d="M283.2 512c79 0 151.1-35.9 198.9-94.8 7.1-8.7-.6-21.4-11.6-19.4-124.2 23.7-238.3-71.6-238.3-197 0-72.2 38.7-138.6 101.5-174.4 9.7-5.5 7.3-20.2-3.8-22.2A258.2 258.2 0 0 0 283.2 0c-141.3 0-256 114.5-256 256 0 141.3 114.5 256 256 256z"/></svg>
          </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDark: false,
    };
  },
  created() {
    this.isDark = localStorage.getItem('theme') === 'dark';
    if (this.isDark) {
      document.documentElement.classList.add('dark');
    }
  },
  methods: {
    toggleTheme() {
      this.isDark = !this.isDark;
      if (this.isDark) {
        document.documentElement.classList.add('dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('theme', 'light');
      }
    },
  },
};
</script>

<style scoped>

:root {
  --color-light-theme: #ccc; /* Цвет для светлой темы */
  --color-dark-theme: #2196F3; /* Цвет для темной темы */
  --icon-color-light: black; /* Цвет иконки для светлой темы */
  --icon-color-dark: white; /* Цвет иконки для темной темы */
}

.theme-switch-wrapper {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    /* Убедитесь, что есть внутренний отступ, чтобы тумблер не касался краёв */
    padding: 4px;
    border-radius: 34px; /* радиус скругления */
    background-color: var(--color-light-theme); /* Используем переменную для цвета фона */
    transition: all 1s;

}

.theme-switch__input {
    opacity: 0;
    width: 0;
    height: 0;
}

.theme-switch__label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--icon-color-light); /* Используем переменную для цвета текста и иконок */
    transition: .4s;
    border-radius: 34px;
}

.theme-switch__label:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px; /* Оставьте этот стиль как есть, чтобы круг оставался слева */
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.theme-switch__icon .icon {
    position: absolute;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    fill: currentColor;
    transition: fill .3s ease; /* Плавное изменение цвета иконки */
}

.theme-switch__input:not(:checked) + .theme-switch__label .icon-sun {
    left: 50%; /* Центрируем относительно переключателя */
    margin-left: -6px; /* Сдвиг на половину ширины иконки */
    opacity: 1; /* Иконка солнца видна при светлой теме */
}

.theme-switch__input:checked + .theme-switch__label .icon-moon {
    right: 50%; /* Центрируем относительно переключателя */
    margin-right: -6px; /* Сдвиг на половину ширины иконки */
    opacity: 1; /* Иконка луны видна при темной теме */
    color: #19173D;
}

/* Скрываем иконку луны когда не активно */
.theme-switch__input:not(:checked) + .theme-switch__label .icon-moon {
    opacity: 0; /* Иконка луны скрыта при светлой теме */
}

/* Скрываем иконку солнца когда активно */
.theme-switch__input:checked + .theme-switch__label .icon-sun {
    opacity: 0; /* Иконка солнца скрыта при темной теме */
}

.theme-switch__label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #368ccc24; /* Сделаем фон прозрачным, так как цвет уже на wrapper */
    transition: all 1s;
}

.theme-switch__label:before {
    /* Круг тумблера должен изначально быть слева */
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    z-index: 100;
}

.theme-switch__input:checked + .theme-switch__label {
  background-color: #fff3;
  transition: all 1s;
}

/* При активации чекбокса, круг должен сдвигаться вправо */
.theme-switch__input:checked + .theme-switch__label:before {
    transform: translateX(24px);
    background-color: #368CCC;
    transition: 0.4s;
}

.theme-switch__icon .icon {
    /* Уберём display: none, чтобы иконки были видимы */
    position: absolute;
    color: #89E3FF;
    top: 50%;
    left: 0; /* Иконка будет начинаться слева */
    transform: translate(100%, -50%); /* Центрировать относительно круга тумблера */
    fill: currentColor;
    transition: .4s; /* Добавим анимацию для плавного переключения иконок */
}

.theme-switch__icon .icon-sun {
  color: yellow;
}

.theme-switch__icon .icon-sun {
  left: 50%;
  color: #ffce35;
  margin-left: -6px;
  transition: all 1s;
}

/* Сдвигаем иконку солнца вправо, когда чекбокс активен */
/* .theme-switch__input:checked + .theme-switch__label .icon-sun {
    transform: translate(200%, -50%);
} */

/* Сдвигаем иконку луны на место круга, когда чекбокс активен */
/* .theme-switch__input:checked + .theme-switch__label .icon-moon {
    transform: translate(100%, -50%);
    display: block;
} */

.theme-switch__input:not(:checked) + .theme-switch__label .icon-moon {
    /* Сдвигаем иконку луны влево, когда чекбокс неактивен */
    display: none; /* Скрываем луну, когда светлая тема активна */
}

.theme-switch__input:not(:checked) + .theme-switch__label .icon-sun {
    /* Убедимся, что солнце отображается на своём месте */
    display: block;
}

/* Скрываем иконку луны в светлой теме */
.theme-switch__input:not(:checked) + .theme-switch__label .icon-moon {
  opacity: 0;
}

/* Скрываем иконку солнца в темной теме */
.theme-switch__input:checked + .theme-switch__label .icon-sun {
  opacity: 0;
}







</style>
