<template>
  <div class="services--block flex justify-center">
      <div class="container--lg">
        <div class="finder--block">
          <div class="finder--header">
            <div class="finder--header__left">
              <div class="f_h_right--text">
                <h3>👨‍💻 Найти эксперта</h3>
              </div>
              <hr>
              <div class="relative flex justify-between w-full flex-col"> 
                <div class="input-find--block">
                  <input class="input-find--el" type="text" name="expert--founder"
                    placeholder="Введите @username Telegram ..."
                    v-model="findField"
                    @input="debouncedSearch"
                    @keydown.enter="selectCurrent"
                    @keydown.down="selectNext"
                    @keydown.up="selectPrevious"
                  >
                  <button class="find--button" v-on:click="this.findUser()">
                    <img class="search--logo" src="@/assets/images/search.svg">
                  </button>
                </div>
                <div class="search-results search-results__desktop" v-if="!isMobile && searchResults.length > 0">
                  <ul>
                    <li
                      v-for="(result, index) in searchResults"
                      :key="index"
                      @click="selectResult(result.username)"
                      :class="{ active: index === selectedResultIndex }"
                      class="result-item"
                    >
                      {{ result.account_button }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="search-results" v-if="isMobile">
                <ul v-if="searchResults.length > 0">
                  <li
                    v-for="(result, index) in searchResults"
                    :key="index"
                    @click="selectResult(result.username)"
                    :class="{ active: index === selectedResultIndex }"
                    class="result-item"
                  >
                    {{ result.account_button }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="finder--header__right">
              <p>👤  Пользователей: {{ count.accounts }}</p>
              <p>👨‍💻  Экспертов: {{ count.experts }}</p>
            </div>
          </div>

          <div class="finder--content" id="finderBlock" ref="finderBlock">

            <div class="finder--content__block" v-if="categories">
              <h3 class="finder--content__title">Выбери сферу экспертности:</h3>
              <div class="finder--content__items">
                  
                <button class="finder--content__c-item" v-for="category in categories" :key="category.id" :class="selected.category == category.id  ? 'selected' : []" v-on:click="this.selectCategory(category.id)">
                  <h5>{{ category.text }} :</h5>
                  <p>{{ category.count }}</p>
                </button>

              </div>
            </div>


            <div class="finder--content__block" v-if="subcategories">
              <h3 class="finder--content__title">Выбери экспертную область в сфере:</h3>
              <div class="finder--content__items">
                  
                <button class="finder--content__c-item" v-for="subcategory in subcategories" :key="subcategory.id" :class="selected.subcategory == subcategory.id  ? 'selected' : []" v-on:click="this.selectSubcategory(subcategory.id)">
                  <h5>{{ subcategory.text }} :</h5>
                  <p>{{ subcategory.count }}</p>
                </button>

              </div>
            </div>


            <div class="finder--content__block" v-if="users">
              <h3 class="finder--content__title">Эксперты данной сферы:</h3>
              <div class="finder--content__items">

                <button class="finder--content__c-item" v-for="user in users" :key="user.id" :class="selected.user == user.id  ? 'selected' : []" v-on:click="this.selectUser(user.id)">
                  <h5>{{ user.account_button }}</h5>
                  <img class="finder--arrow" src="@/assets/images/arrow-right.svg">
                </button>

              </div>
            </div>


            <div class="finder--content__block--profile" v-if="selected.user && selected.publication == false">

              <div class="finder--content__block profile--block__text">
                <h3 class="finder--content__title">Профиль</h3>
                <div class="finder--content__items">

                  <div class="top_comment_content_people--block">
                    <img class="charter_laptop_man" src="@/assets/images/man_laptop.svg" alt="Стикер мужчина за компьютером">
                    <p class="comment_content_people_blue--title" v-html="expert.account_connect"></p>
                  </div>

                  <p class="comment_content_people_black--title">👨 {{expert.full_name}}</p>

                  <!-- <div class="top_comment_content_people--block expert_top_comment_content_people">
                    <img class="expert_laptop_man" src="@/assets/images/man_laptop.svg" alt="Стикер мужчина за компьютером">
                    <p class="comment_content_people_expert--title">Сферы экспертности:</p>
                  </div> -->

                  <div class="expert-photo--block__find">
                    <img v-if="expert.profile_photo" :src="$store.state.base_url + expert.profile_photo">
                    <img v-else src="@/assets/images/globus.svg">
                  </div>
                  
                  <p class="part_sphere" v-html="expert.spheres_text"></p>
                  <br>
                  <!-- <p class="part_sphere">Приглашенных: {{expert.referals_count}}</p> -->
                  <p class="part_sphere">Приглашенных:<br>Пользователей - {{expert.referals_count}}<br>Экспертов - {{expert.referals_experts_count}}</p>
                  <div class="flex flex-col w-full p-2.5">
                    <p class="text-md mb-1 text-black">Просмотров: {{ expert.page_views_all }}</p>
                    <p class="text-sm relative text-black"><span class="text-xs right-full">👁</span> <span class="text-black"> {{ expert.page_views_today ? `+ ${expert.page_views_today}` : 0 }}</span></p>
                  </div>
                </div>
              </div>

              <div class="profile--block__buttons">
                <a class="profile--button blue" v-if="expert.have_page" :href="expert.page_url">Страница эксперта</a>
                <a class="profile--button" v-if="expert.account_card" :href="expert.page_url">Визитка эксперта</a>
                <!-- <a class="profile--button" v-if="expert.publications" v-for="publication in expert.publications" :key="publication.id" :href="expert.page_url" target="_blank">{{ publication.event_name }}</a> -->
                <button class="profile--button" v-if="expert.publications" v-for="publication in expert.publications" :key="publication.id" @click="getPublication(publication.id)">{{ publication.event_name }}</button>
                <a class="profile--button" v-if="expert.links" v-for="link in expert.links" :key="link.id" :href="link.url" target="_blank">{{ link.name }}</a>
              </div>
            </div>


            <div class="finder--content__block--profile" v-if="selected.publication">

              <div class="finder--content__block profile--block__text">
                <div class="finder--publication-header--block">
                  <button class="finder--publication_arrow" @click="backProfile()">
                    <img class="finder--publication_arrow--img" src="@/assets/images/arrow-left.png" alt="Стрелка влево">
                  </button>
                  <h3 class="finder--content__title">Публикация</h3>
                </div>
                <div class="finder--content__items">
                  <p class="comment_content_people_black--title">{{publication.event_name}}</p>
                  <img class="finder--publication_photo" :src="$store.state.base_url + publication.file" :alt="publication.event_name">
                  <p class="finder--publication_text" v-html="publication.description"></p>
                </div>
              </div>

              <div class="profile--block__buttons">
                <a class="profile--button blue" v-if="expert.have_page" :href="expert.page_url">Страница эксперта</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="container finder--title">Последняя десятка экспертов</h2>

      <div class="team_block--black">
        <div id="sphereLastExperts" class="container content_mobile_style comment_content_people--blocks prokrutka" v-if="this.lastExperts">

          <!-- Подстановка данных с бекенда -->
          <div class="top-expert--block" v-for="expert in this.lastExperts">
            <div class="comment_content_people--block">
              <div class="top_comment_content_people--block">
                <img class="charter_laptop_man" src="@/assets/images/man_laptop.svg" alt="Стикер мужчина за компьютером">
                <p class="comment_content_people_blue--title" v-html="expert.account_connect"></p>
              </div>
              <p class="comment_content_people_black--title">👨 {{expert.full_name}}</p>

              <div class="expert-photo--block__find">
                <img v-if="expert.profile_photo" :src="$store.state.base_url + expert.profile_photo">
                <img v-else src="@/assets/images/globus.svg">
              </div>

              <div class="top_comment_content_people--block expert_top_comment_content_people">
                <img class="expert_laptop_man" src="@/assets/images/man_laptop.svg" alt="Стикер мужчина за компьютером">
                <p class="comment_content_people_expert--title">Сферы экспертности:</p>
              </div>
              <p class="part_sphere" v-html="expert.spheres_text"></p>
              <br>
              <p class="part_sphere">Дней в проекте: {{expert.days}}</p>
              <br>
              <p class="part_sphere">Приглашенных:<br>Пользователей - {{expert.referals_count}}<br>Экспертов - {{expert.referals_experts_count}}</p>
            </div>
            <div class="profile--block__buttons">
              <a class="profile--button blue" v-if="expert.have_page" :href="expert.page_url" target="_blank">Страница эксперта</a>
            </div>
          </div>

        </div>
      </div>
  </div>
</template>


<script>
import axios from 'axios';
import { debounce, isMobile, expertView  } from '../utils'
import { viewSource } from '../constants'
import { mapActions, mapState } from "vuex";

export default {
  name: 'ExpertView',
  computed: {
    ...mapState(["lastExperts"]),
    expertId() {
      return this.$route.params.id ? +this.$route.params.id : false;
    }
  },
  data() {
    return {
      findField: '',
      selected: {
        category: false,
        subcategory: false,
        user: false,
        publication: false
      },
      count: {
        accounts: 0,
        experts: 0
      },
      categories: false,
      subcategories: false,
      users: false,
      expert: false,
      publication: false,

      searchResults: [],
      selectedResultIndex: -1,
      debouncedSearch: debounce(this.search, 300),
      isMobile: isMobile()
    }
  },
  components: {},
  created() {
    this.getCategories();
    this.getLastExperts();
  },
  methods: {
    ...mapActions(["getLastExperts"]),
    getCategories() {
      axios.get(`${this.$store.state.base_url}/api/finder/categories/`)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data);
          this.categories = response.data.data
          this.count.accounts = response.data.accounts_count
          this.count.experts = response.data.experts_count
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    selectCategory(categoryId) {
      if (this.selected.category != categoryId) {
        this.selected = {
          category: categoryId,
          subcategory: false,
          user: false,
          publication: false
        }
        this.subcategories = false;
        this.users = false;
        this.expert = false;
        this.getSubcategories();
      }
    },
    getSubcategories() {
      axios.get(`${this.$store.state.base_url}/api/finder/subcategories/`, {
        params: {
          category: this.selected.category
        }
      }).then(response => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          this.subcategories = response.data.data;
          this.scrollRight();
        }
      }).catch(error => {
        console.log(error)
      })
    },
    selectSubcategory(subcategoryId) {
      if (this.selected.subcategory != subcategoryId) {
        this.selected.subcategory = subcategoryId;
        this.selected.user = false;
        this.selected.publication = false;
        this.users = false;
        this.expert = false;
        this.getUsers();
      }
    },
    getUsers() {
      axios.get(`${this.$store.state.base_url}/api/finder/users/`, {
        params: {
          subcategory: this.selected.subcategory
        }
      }).then(response => {
        if (response.status === 200) {
          this.users = response.data.data
        }
        this.scrollRight();
      }).catch(error => {
        console.log(error)
      })
    },
    selectUser(expertId) {
      if (this.selected.user != expertId) {
        this.selected.user = expertId;
        this.selected.publication = false;
        this.getExpert(expertId);
      }
    },

    findUser() {
      console.log(this.findField, this.expert.username);
      if (this.findField != '' && this.findField != this.expert.username) {
        this.selected = {
          category: false,
          subcategory: false,
          user: false,
          publication: false
        }
        this.subcategories = false;
        this.users = false;
        this.expert = false;
        this.getExpert();
      }
    },
    async getExpert() {
      let params = {};
      if (this.selected.user) {
        params = {
          expertId: this.selected.user
        }
      } else {
        params = {
          expertUsername: this.findField
        }
      }
      await axios.get(`${this.$store.state.base_url}/api/expert-short/get/`, {
        params: params
      }).then(response => {
        console.log(response);
        if (response.status === 200) {
          this.expert = response.data;
          this.selected.user = this.expert.id;
          this.selected.publication = false;
          this.scrollRight()
          expertView(
            this.$store.state.base_url,
            this.expert.id,
            null, // todo - get authorized viewer id
            viewSource.EXPERT_FIND_VIEW
          );
        } else {
          this.selected = {
            category: false,
            subcategory: false,
            user: false,
            publication: false
          }
          this.subcategories = false;
          this.users = false;
          this.expert = false
          this.selected.user = false
        }
      }).catch(error => {
        console.log(error)
      })
    },


    getPublication(publicationId) {
      let params = {
        publicationId: publicationId
      }
      axios.get(`${this.$store.state.base_url}/api/publication/get/`, {
        params: params
      }).then(response => {
        if (response.status === 200) {
          this.selected.publication = publicationId;
          this.publication = response.data;
          this.scrollRight()
        } else {
          this.selected = {
            category: false,
            subcategory: false,
            user: false,
            publication: false
          }
          this.subcategories = false;
          this.users = false;
          this.expert = false;
        }
      }).catch(error => {
        console.log(error)
      })
    },
    backProfile() {
      this.selected.publication = false;
      this.publication = false;
      this.selected.user = this.expert.id;
    },


    scrollRight() {
      setTimeout(() => {
        let content = document.querySelector("#finderBlock");
        content.scrollLeft += window.screen.width - 20;
      }, 400)
    },

    search() {
      if (this.findField === '') {
        this.searchResults = [];
        return;
      }
    
      let params = {
        expertUsername: this.findField
      }
      axios.get(`${this.$store.state.base_url}/api/autocomplete-experts/get/`, {
        params
      })
        .then(response => {
          this.searchResults = response.data.accounts;
        })
        .catch(error => {
          console.error('Error fetching search results:', error);
        });
    },
    selectNext() {
      this.selectedResultIndex = Math.min(this.selectedResultIndex + 1, this.searchResults.length - 1);
    },
    selectPrevious() {
      this.selectedResultIndex = Math.max(this.selectedResultIndex - 1, -1);
    },
    selectCurrent() {
      if (this.selectedResultIndex !== -1) {
        this.selectResult(this.searchResults[this.selectedResultIndex].username);
      }
      this.findUser()
    },
    selectResult(username) {
      this.findField = username;
      this.searchResults = [];
      this.selectedResultIndex = -1;
      this.findUser()
    },
  },
}
</script>


<style scoped>

.finder--title {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  color: hsla(0,0%,100%,.4);
  padding-top: 60px;
  padding-bottom: 17px;
  background: none!important;
}

.dark .finder--title {
  color: #19173D;
}

.expert-photo--block__find img {
  width: 120px;
  height: 120px;
  margin: 20px auto 0;
  border-radius: 50% 50%;
}

.profile--block__buttons {
  width: auto !important;
  margin-left: 10px;
  margin-right: 10px;
}

.services--block {
  display: flex;
  flex-direction: column;
}

.eye_text_icon {
  bottom: -5px;
}

.autocomplete-container {
  position: relative;
}

.search-input {
  padding: 8px;
  width: 300px;
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: none;
}

.search-results__desktop {
  position: absolute;
  top: 100%;
  width: 104%;
  left: -2%;
  z-index: 1;
  padding: 0 2% 2%;
  background-color: #fff;
  border-radius: 18px;
}

.result-item {
  cursor: pointer;
}

.result-item.active {
  background-color: #eee;
}

.end--finder {
  width: 1%;
  background: none;
  display: flex;
}

.container--big {
  margin: 0 50px;
}
  
.finder--block {
  width: 100%;
  margin-top: 30px;
}

.finder--header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.finder--header * {
  color: #000000;
}

.finder--header__left {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  padding: 15px 40px;
  border-radius: 18px;
  gap: 20px;
}

.dark .finder--header__left {
  background: #a5a5a5;
}

.f_h_right--text {
  width: auto;
  white-space: nowrap;
  font-size: 22px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  line-height: 108.5%;
}

.input-find--block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.input-find--el {
  width: 100%;
  border: none !important;
  border-radius: 3px;
  color: #616161;
  padding: 4px 8px;
  font-size: 24px;
  font-family: 'Source Sans Pro';
  line-height: 108.5%;
}
.dark .input-find--el {
  background-color: #ffffffba;
  padding: 4px 8px;
}

.find--button {
  border: none;
  outline: 2px solid #FFFFFF;
  border-radius: 18px;
  background: none;
  transition: 0.4s;
}

.find--button:hover {
  transition: 0.4s;
  outline: 2px solid #0B7EE9;
}

.search--logo {
  width: 36px;
  height: 36px;
}

.finder--header__left > hr {
  width: 35px;
  height: 2px;
  transform: rotate(90deg);
  background: #000000;
  border: none;
}

.finder--header__right {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #FFFFFF;
  padding: 15px 30px;
  border-radius: 18px;
}

.finder--header__right > * {
  white-space: nowrap;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-weight: 600;
  line-height: 108%;
}

.finder--content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5%;
  margin-top: 30px;
}

.finder--content__block--profile {
  width: 100%;
  max-width: 24%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
}

.finder--content__block {
  position: relative;
  width: 100%;
  max-width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;
  border-radius: 20px;
  background: #1D1D24;
  height: 500px;
}

.dark .finder--content__block {
  background: #368CCC;
}

.profile--block__text {
  max-width: 100%;
  background: #FFFFFF;
}

.profile--block__buttons {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.profile--button {
  width: 100%;
  padding: 7px;
  border-radius: 10px;
  background: #1D1D24;
  color: #FFFFFF;
  text-align: center;
  outline: 2px solid #1D1D24;
  transition: 0.4s;
}

.profile--button:hover {
  transition: 0.4s;
  color: #0B7EE9;
  outline: 2px solid #0B7EE9;
}

.profile--button.blue {
  background: #0B7EE9;
  outline: 2px solid #0B7EE9;
}

.profile--button.blue:hover {
  transition: 0.4s;
  color: #FFFFFF;
  outline: 2px solid #FFFFFF;
}

.finder--publication_photo {
  width: 100%;
  margin-top: 10px;
}

.finder--publication-header--block {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.finder--publication_arrow {
  position: absolute;
  top: 14px;
  left: 10px;
  width: 30px;
  height: 20px;
  background: none;
  border: 0;
}

.finder--publication_arrow--img {
  width: 100%;
  height: 100%;
}

.finder--publication_text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 108.5%;
  color: #fff;
  padding-top: 6px;
}



.finder--content__items {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
  color: #fff;
}

.finder--content__items::-webkit-scrollbar {
  width: 5px;
}

.finder--content__items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.finder--content__items::-webkit-scrollbar-thumb {
  background-color: #0B7EE9;
  outline: 1px solid #0B7EE9;
  border-radius: 3px;
}

.finder--content__title {
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0;
}

.profile--block__text .finder--content__title {
  color: #000000;
}

.finder--content__c-item {
  background: none;
  border: none;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  border-bottom: 1px solid #0B7EE9;
  cursor: pointer;
  transition: 0.4s;
}

.finder--content__c-item * {
  cursor: pointer;
  color: #fff;
}

.finder--content__c-item.selected {
  width: 100%;
  margin: 0;
  background: #0B7EE9;
  color: #FFFFFF;
  border-radius: 10px;
  transform: translateY(-1px);
}
.dark .finder--content__c-item.selected {
  background: #0C1322;
  color: #89E3FF;
}

.finder--content__c-item:hover > .finder--arrow {
  transform: translateX(10px);
  transition: 0.4s;
}

.finder--content__c-item * {
  font-size: 14px;
  font-weight: 500;
  line-height: 108.5%;
  color: #fff;
/*  font-family: 'Inter';*/
/*  line-height: 10px;*/
}

.finder--arrow {
  transition: 0.4s;
}




.comment_content_people_black--title {
  margin: 0 10px;
}

.expert_laptop_man {
  margin: 0 10px;
}






@media (max-width: 701px) {


  .finder--publication_text {
    font-size: 18px;
  }

  .container--big {
    width: auto;
    margin: 0 20px;
  }

  .finder--header {
    flex-direction: column-reverse;
  }

  .finder--header__left {
    flex-direction: column;
    padding: 10px 20px;
  }

  .finder--header__left hr {
    display: none;
  }

  .input-find--el {
    font-size: 20px;
  }


  .finder--content {
    width: auto;
    overflow-x: auto;
    padding-bottom: 20px;
  }

  .finder--content::-webkit-scrollbar {
    width: 5px;
  }

  .finder--content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  .finder--content::-webkit-scrollbar-thumb {
    background-color: #0B7EE9;
    outline: 1px solid #0B7EE9;
    border-radius: 5px;
  }

  .finder--content__block {
    width: 100%;
    min-width: 100%;
    height: 400px;
  }

  .finder--content__items {
    padding: 0 10px;
  }

  .finder--content__block--profile {
    min-width: 80%;
  }


}





</style>


