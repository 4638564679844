<template>
  <div class="services--block flex justify-center">
    <div class="container--lg">


      <div class="finder--block">

        <div class="finder--content" id="finderBlock" ref="finderBlock">

          <div class="finder--content__block" v-if="categories">
            <h3 class="finder--content__title">Выберите сферу:</h3>
            <div class="finder--content__items">
                
              <button class="finder--content__c-item" v-for="category in categories" :key="category.id" :class="selected.category == category.id  ? 'selected' : []" v-on:click="this.selectCategory(category.id)">
                <h5>{{ category.text }} :</h5>
                <p>{{ category.count }}</p>
              </button>

            </div>
          </div>


          <div class="finder--content__block" v-if="subcategories">
            <h3 class="finder--content__title">Выберите нишу:</h3>
            <div class="finder--content__items">
                
              <button class="finder--content__c-item" v-for="subcategory in subcategories" :key="subcategory.id" :class="selected.subcategory == subcategory.id  ? 'selected' : []" v-on:click="this.selectSubcategory(subcategory.id)">
                <h5>{{ subcategory.text }} :</h5>
                <p>{{ subcategory.count }}</p>
              </button>

            </div>
          </div>


          <div class="finder--content__block" v-if="events">
            <h3 class="finder--content__title">Чаты по публикациям:</h3>
            <div class="finder--content__items">

              <button class="finder--content__c-item" v-for="event in events" :key="event.id" :class="selected.event == event.id  ? 'selected' : []" v-on:click="this.selectEvent(event.id)">
                <h5>{{ event.publication_button }}</h5>
                <img class="finder--arrow" src="@/assets/images/arrow-right.svg">
              </button>

            </div>
          </div>


          <div class="finder--content__block--profile" v-if="selected.event">

            <div class="finder--content__block profile--block__text">
              <div class="finder--publication-header--block">
                <h3 class="finder--content__title">Публикация</h3>
              </div>
              <div class="finder--content__items">
                <p class="comment_content_people_black--title">{{publication.event_name}}</p>
                <img class="finder--publication_photo" :src="$store.state.base_url + publication.file" :alt="publication.event_name">
                <p class="finder--publication_text" v-html="publication.description"></p>
              </div>
            </div>

            <div class="profile--block__buttons" v-if="publication.author">
              <a class="profile--button blue" :href="`/expert/${publication.author}`">Страница эксперта</a>
            </div>
          </div>
        </div>
      </div>


      <div class="messenger--notion">
        <h3>Ищите публикации/ивенты от наших Экспертов в 3 клика. Или создайте свой, прямо сейчас!</h3>
        <!-- <div class="messenger-chats--block"> -->
          <!-- Цикл -->

              <!-- Текст в кружке или картинка -->
          <!-- <div class="messenger-chat--element">
            <div class="chat-element--logo">
              <p>JТ</p>
            </div>
            <div class="chat-element--text">
              <h4 class="chat-element--title">Jedels Tech.</h4>
              <p class="chat-element--last-message">Последнее сообщение</p>
            </div>
          </div>

          <div class="messenger-chat--element">
            <div class="chat-element--logo">
              <p>JТ</p>
            </div>
            <div class="chat-element--text">
              <h4 class="chat-element--title">SPHERE</h4>
              <p class="chat-element--last-message">Последнее сообщение</p>
            </div>
          </div> -->

          <!-- Конец цикла -->
        <!-- </div>


        <div class="messenger-chat--communication"> -->
          <!-- <div class="chat--content">
            <div class="chat--messages">
              <div class="message from">
                <label>Приветик</label>
              </div>
              <div class="message to">
                <label>Приветик</label>
              </div>
          </div>
            <div class="chat--bottom">
              <input type="text" class="messenger-chat--input" />
              <button class="messenger-chat--send">Отправить</button>
            </div>
          </div> -->

          <!-- <div class="chat--info">
            <div class="finder--content__block profile--block__text">
              <div class="finder--publication-header--block">
                <button class="finder--publication_arrow" @click="backProfile()">
                  <img class="finder--publication_arrow--img" src="@/assets/images/arrow-left.png" alt="Стрелка влево">
                </button>
                <h3 class="finder--content__title">Публикация</h3>
              </div>
              <div class="finder--content__items">
                <p class="comment_content_people_black--title">{{publication.event_name}}</p>
                <img class="finder--publication_photo" :src="$store.state.base_url + publication.file" :alt="publication.event_name">
                <p class="finder--publication_text" v-html="publication.description"></p>
              </div>
            </div>
          </div> -->
        <!-- </div> -->

      
      </div>
      <!-- <div v-else class="messenger--notion">
        <h3>Для доступа к мессенджеру - авторизуйтесь</h3>
      </div> -->

    </div>

    <h2 class="container finder--title">Последняя десятка публикаций</h2>

    <div class="team_block--black">
      <div id="sphereEvents" class="container content_mobile_style comment_content_people--blocks prokrutka" v-if="lastEvents">

        <!-- Подстановка данных с бекенда -->
        <div class="top-expert--block" v-for="event in lastEvents">
          <div class="finder--content__block profile--block__text comment_content_people--block">
              <div class="finder--publication-header--block">
                <h3 class="finder--content__title">Публикация</h3>
              </div>
              <div class="finder--content__items">
                <p class="comment_content_people_black--title">{{event.event_name}}</p>
                <img class="finder--publication_photo" :src="$store.state.base_url + event.photo" :alt="event.event_name">
                <p class="finder--publication_text" v-html="event.description"></p>
              </div>
            </div>

            <div class="profile--block__buttons" v-if="event.author">
              <a class="profile--button blue" :href="`/expert/${event.author}`">Страница эксперта</a>
            </div>
        </div>

      </div>
    </div>

  </div>
</template>


<script>
import Vue from 'vue';
// import { mapState } from "vuex";
import axios from 'axios';
// import VueNativeSock from 'vue-native-websocket';

import { mapActions, mapState } from "vuex";


import '../views/MessengerView.css';

export default {
  name: 'EventsView',
  data() {
    return {
      findField: '',
      selected: {
        category: false,
        subcategory: false,
        event: false,
      },
      categories: false,
      subcategories: false,
      events: false,
      publication: false,
      messages: [],
    }
  },
  components: {},
  computed: {
    ...mapState(["lastEvents"]),
    publicationId() {
      return this.$route.params.id ? +this.$route.params.id : false;
    }
  },
  created() {
    this.getCategoriesPublications();
    this.getEvents();
    // this.getChats();
    // this.pingServer();
  },
  sockets: {
    // connect() {
    //   // Fired when the socket connects.
    //   this.isConnected = true;
    // },

    // disconnect() {
    //   this.isConnected = false;
    // },

    // // Fired when the server sends something on the "messageChannel" channel.
    // messages(data) {
    //   this.socketMessage = data
    // }
  },
  methods: {
    ...mapActions(["getEvents"]),
    // pingServer() {
    //   // Send the "pingServer" event to the server.
    //   this.$socket.emit('pingServer', 'PING!')
    // },
    getChats() {
      axios.get(`${this.$store.state.base_url}/api/messenger/chats/`, {
        params: {
          useruid: this.$store.state.user.id
        }
      })
      .then(response => {
        if (response.status === 200) {
          console.log(response.data);
          this.categories = response.data.data
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    getCategoriesPublications() {
      axios.get(`${this.$store.state.base_url}/api/finder/categories-publications/`)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data);
          this.categories = response.data.data
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    selectCategory(categoryId) {
      if (this.selected.category != categoryId) {
        this.selected = {
          category: categoryId,
          subcategory: false,
          event: false,
        }
        this.subcategories = false;
        this.events = false;
        this.publication = false;
        this.getSubcategories();
      }
    },
    getSubcategories() {
      axios.get(`${this.$store.state.base_url}/api/finder/subcategories-publications/`, {
        params: {
          category: this.selected.category
        }
      }).then(response => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          this.subcategories = response.data.data;
          this.scrollRight();
        }
      }).catch(error => {
        console.log(error)
      })
    },
    selectSubcategory(subcategoryId) {
      if (this.selected.subcategory != subcategoryId) {
        this.selected.subcategory = subcategoryId;
        this.selected.event = false;
        this.events = false;
        this.publication = false;
        this.getUsers();
      }
    },
    getUsers() {
      axios.get(`${this.$store.state.base_url}/api/messenger/publications/`, {
        params: {
          subcategory: this.selected.subcategory
        }
      }).then(response => {
        if (response.status === 200) {
          this.events = response.data.data
        }
        this.scrollRight();
      }).catch(error => {
        console.log(error)
      })
    },
    selectEvent(publicationId) {
      if (this.selected.event != publicationId) {
        this.selected.event = publicationId;
        this.getPublication(publicationId);
      }
    },
    getPublication() {
      let params = {};
      if (this.selected.event) {
        params = {
          publicationId: this.selected.event
        }
      } else {
        params = {
          publicationUsername: this.findField
        }
      }
      axios.get(`${this.$store.state.base_url}/api/publication/get/`, {
        params: params
      }).then(response => {
        console.log(response);
        if (response.status === 200) {
          this.publication = response.data;
          this.selected.event = this.publication.id;
          this.scrollRight()
        } else {
          this.selected = {
            category: false,
            subcategory: false,
            event: false,
          }
          this.subcategories = false;
          this.events = false;
          this.publication = false
          this.selected.event = false
        }
      }).catch(error => {
        console.log(error)
      })
    },
    scrollRight() {
      setTimeout(() => {
        let content = document.querySelector("#finderBlock");
        content.scrollLeft += window.screen.width - 20;
      }, 400)
    }
  },
}
</script>


<style scoped>
  .finder--title {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    color: hsla(0,0%,100%,.4);
    padding-top: 60px;
    padding-bottom: 17px;
    background: none!important;
  }

  .team_block--black {
    margin-top: 40px;
  }

  .profile--block__buttons {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

  .comment_content_people--blocks {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .top-expert--block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
  }

  .comment_content_people--block {
    display: flex;
    flex-direction: column;
  }
  
  .services--block {
    display: flex;
    flex-direction: column;
  }

</style>