<template>
  <div class="header_jedels--block styled-header h-auto -ml-1">
    <div class="header--menu header-container container content_mobile_style">
      <a class="mobile-view" href="/" v-if="!enableBackBtn">
        <img class="w-9 h-9"  src="@/assets/images/globus.svg" alt="sphere experts logo">
      </a>
      <a class="mobile-view" v-if="enableBackBtn" @click="goBack()">
        <img class="w-9 h-9"  src="@/assets/images/back-arrow.svg" alt="back icon">
      </a>
      <a class="desktop-view label_header" href="/">
        <img class="w-6 h-6 mr-1"  src="@/assets/images/globus.svg" alt="sphere experts logo">
        SPHERE
      </a>
      <div class="middle_menu_header--block">
        <a class="header_text_middle_menu blog_style--text" href="/events" :class="{ active: $route.name === 'Messenger'}">Публикации</a>
        <!-- <a class="header_text_middle_menu blog_style--text" href="/stats" :class="{ active: $route.name === 'Stats' }">Статистика</a> -->
        <a class="header_text_middle_menu blog_style--text" href="/find" :class="{active: $route.name === 'Find'}">Поиск экспертов</a>
      </div>
      <div class="social_header--block">
        <div class="header-controls">
          <theme-switcher />
          <div>
            <a v-if='this.$store.state.authorized == true && this.$store.state.user' class="authorized--block" :href="'/expert/' + this.$store.state.user.id">
              <p class="blog_style--text">{{ this.$store.state.user.full_name ? this.$store.state.user.full_name : "Эксперт" }}</p>
              <img v-if="this.$store.state.user.profile_photo" :src="$store.state.base_url + this.$store.state.user.profile_photo">
            </a>
            <a v-else :href="this.$store.state.expertsBotUrl + '?start=_' + this.uid" class="button--auth flex" target="_blank">
              
              <div>Авторизация</div>
              <img class="w-4 ml-2" src="@/assets/images/telegram_top--button.svg" alt=""/> 
            </a>
          </div>
          <!-- <button id="myButt" class="burger--button mobile-view mobile_block" :style="{'background-color': burger ? '#ffffff' : 'transparent'}" v-on:click="burger=!burger">
            <img class="burger" src="@/assets/images/burger.svg" alt="">
          </button> -->

        </div>
        <!-- <img class="wave_grey" src="@/assets/images/wave_grey.svg" alt=""> -->
        <!-- <Auth mode="callback" :telegram-login="authBotName" size="large"></Auth> -->
      </div>
      <button id="myButt" class="burger-button" @click="burger=!burger" :aria-expanded="burger.toString()">
          <div class="menu-button-container">
            <span class="burger-bar"></span>
            <span class="burger-bar"></span>
            <span class="burger-bar"></span>
          </div>
        </button>
    </div>
    
    <!-- <div class="mobile-view flex">
      <a v-if='this.$store.state.authorized == true && this.$store.state.user' class="authorized--block" :href="'/expert/' + this.$store.state.user.id">
        <p class="blog_style--text">{{ this.$store.state.user.full_name ? this.$store.state.user.full_name : "Эксперт" }}</p>
        <img v-if="this.$store.state.user.profile_photo" :src="$store.state.base_url + this.$store.state.user.profile_photo">
      </a>
      <a v-else :href="this.$store.state.expertsBotUrl + '?start=_' + this.uid" class="button--auth flex content-center justify-center" target="_blank">
        <div>Авторизация</div>
        <img class="w-4 h-4 ml-2" src="@/assets/images/telegram_top--button.svg" alt=""/> 
      </a>
    </div> -->
    
    <!-- <button id="myButt" class="burger--button mobile-view mobile_block" :style="{'background-color': burger ? '#ffffff' : 'transparent'}" v-on:click="burger=!burger">
      <img class="burger" src="@/assets/images/burger.svg" alt="">
    </button> -->
    <img class="wave_grey" src="@/assets/images/wave_grey.svg" alt="">
  </div>
  <div class="burger--menu -ml-1" v-if="burger">
    <div class="burger-content -ml-1">
      <div class="burger-buttons-block">
        <a class="burger_mobile--button" href="/" v-if="$route.name != 'Land'">◦ Главная</a>
        <a class="burger_mobile--button" href="/find">◦ Поиск экспертов</a>
        <a class="burger_mobile--button" href="/events" v-if="$route.name != 'Messenger'">◦ Публикации</a>
        <!-- <a class="burger_mobile--button" href="/stats" v-if="$route.name != 'Stats'">◦ Статистика</a> -->
        <!-- <theme-switcher /> -->
        <theme-switcher-mobile />
        <div class="w-[50px] h-[50px] corner-menu"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { getCookie } from "../utils";
import Auth from './Auth.vue';
import { isMobile } from '../utils';
import ThemeSwitcher from './ThemeSwitcher.vue';
import ThemeSwitcherMobile from './ThemeSwitcherMobile.vue';

export default {
  name: 'Header',
  components: {
    'theme-switcher': ThemeSwitcher,
    'theme-switcher-mobile': ThemeSwitcherMobile,
    Auth
  },
  data() {
    return {
      burger: false,
      uid: null,
      authBotName: '', // process.env.VUE_APP_EXPERTS_BOT_URL.split('/')[3],
      isMobile: isMobile()
    }
  },
  async created() {
    this.uid = getCookie('uniqueId');
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  onUnmounted() {
    window.removeEventListener('resize', this.onResize); 
  },
  computed: {
    enableBackBtn() {
      const referrer = document ? document.referrer : ''
      if (referrer.includes(location.hostname)) return true
      return typeof this.$root.historyCount !== 'undefined' && window.history.length > this.$root.historyCount 
    }
  },
  methods: {  
    onResize() {
      this.isMobile = isMobile()
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>


<style scoped>

.middle_menu_header--block {
  width: 50%;
}

.header-controls {
  display: flex;
}

.theme-switcher {
  margin-right: 12px;
}

.authorized--block .blog_style--text {
  display: flex;
  align-items: center;
}

.styled-header {
  position: fixed;
  width: 100%;
  transition: color 0.5s ease-in-out;
  background-image: linear-gradient(to top, rgba(255, 0, 0, 0), #19173D);
  height: auto;
  margin-left: -1%;
  z-index: 9999;
}

.dark .styled-header {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #121212);
}

.corner-menu {
  background-color: white;
  right: -30px;
  position: absolute;
  bottom: -30px;
  transform: rotate(45deg);
}

.header_jedels--block * {
  transition: color 0.5s ease-in-out;
  color: var(--pure-white);
}

/* .dark .header_jedels--block * {
  color: var(--pure-white-theme); 
} */

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.08);
    }
}

.button--auth {
  width: min-content;
  background: #0B7EE9;
  border-radius: 16px;
  padding: 3px 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: pulse 2.5s infinite;
}

.dark .button--auth {
  background: #368CCC;
}

.button--auth:hover {
  animation: none;
}

.authorized--block {
  display: flex;
  justify-content: center;
  padding: 8px 36px;
  gap: 12px;
  /* font-size: 10px;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 5px 14px; */
  background: var(--custom-blue);
  border-radius: 15px;
  border: 2px solid white;
}

.dark .authorized--block {
  background: var(-custom-blue-theme);
  border-color: #4b5563;
}

.authorized--block img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.menu-button-container {
  width: 40px;
  height: 40px;
  display: contents;
}

.burger-button {
  display: none; /* Скрыта по умолчанию, будет отображаться только в мобильной версии */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px 10px;
  position: relative; /* Для абсолютного позиционирования псевдоэлементов внутри */
}

.burger-button:focus {
  outline: none; /* Убираем контур при фокусе для стиля */
}

.burger-button .burger-bar {
  display: block;
  width: 36px;
  height: 4px;
  border-radius: 4px;
  background-color: #0b7ee9; /* Цвет линии бургера */
  margin: 0px 0px; /* Расстояние между линиями */
  transition: all 0.3s ease-in-out; /* Плавность анимации */
}

/* Стилизация для открытого состояния бургер-меню */
.burger-button[aria-expanded="true"] .burger-bar:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
}

.burger-button[aria-expanded="true"] .burger-bar:nth-child(2) {
  opacity: 0;
}

.burger-button[aria-expanded="true"] .burger-bar:nth-child(3) {
  transform: translateY(-13px) rotate(-45deg);
}


@media (max-width: 701px) {
  .header_text_middle_menu {
    display: none;
  }

  .theme-switcher {
    display: none;
  }

  .header-container {
    width: 100%;
    margin: 0px 8px;
  }

  .styled-header {
    width: 100%;
  }

  .authorized--block {
    height: min-content;
    margin: 0 15px;
  }

  .button--auth {
    margin: 0 auto;
  }

  
  /* .burger--button {
    border: 2px solid #616161;
    padding: 4px;
    border-radius: 6px;
    background: none;
    display: block !important;
  } */

  .burger-button {
    display: flex; /* Будет отображаться только на мобильных устройствах */
    flex-direction: column;
    justify-content: space-around;
    height: 54.43px;
    border-radius: 7px;
    background-color: #19173dbf;
  }

  /* .dark .burger--button {
    border: 2px solid #ffffff;
  } */

  .burger--menu {
    display: flex;
    justify-content: flex-end;
    text-align: start;
    position: fixed;
    top: 47px;
    z-index: 9;
    width: 100%;
    overflow: hidden;
  }
  
    
  .burger-content {
    margin: 0 auto;
    padding: 64px 16px 32px 16px;
    backdrop-filter: blur(9.5px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }

  .burger-buttons-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }

  .burger_mobile--button {
    border: none;
    background: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: left;
    word-spacing: 10px;
  }

  .dark .burger_mobile--button {
    color: #000;
  }

  .social_header--block {
    /* width: 30%; */
    margin: 0 auto;
  }




}


</style>
