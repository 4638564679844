<template>
  <div class="footer_jedels">
    <div class="container">
      <div class="footer--menu">
        <div class="logo_footer--block">
          <h2 class="label_header">SPHERE</h2>
          <p class="data_creation">© 2023-2024</p>
          <h2 class="label_jedels">Разработано Sphere & Jedels Tech.</h2>
        </div>

        <div class="middle_socail_footer--blocks">
          <div class="social_footer--column social_footer--column--big">
            <a class="social_net_footer--block footer-background--block" :href="`${this.$store.state.expertsBotUrl}`" target="_blank">
              <img class="image_footer" src="@/assets/images/telegram.svg" alt="tg icon">
              <h3 class="label_social--title">BOT EXPERTS</h3>
            </a>
            <a class="social_net_footer--block footer-background--block" href="https://t.me/sphere_experts" target="_blank">
              <img class="image_footer" src="@/assets/images/telegram.svg" alt="tg icon">
              <h3 class="label_social--title">Chanell TG</h3>
            </a>
          </div>
          <div class="social_footer--column">
            <a class="social_net_footer--block" href="https://www.instagram.com/sergey_sinichenko/" target="_blank">
              <img class="image_footer instagram_mobile--image" src="@/assets/images/instagram.svg" alt="instagramm">
              <h3 class="label_social--title">Instagram</h3>
            </a>
            <a class="social_net_footer--block" href="https://www.youtube.com/@sphere-experts" target="_blank">
              <img class="image_footer" src="@/assets/images/youtube.svg" alt="youtube">
              <h3 class="label_social--title">YouTube</h3>
            </a>
            <a class="social_net_footer--block" href="https://www.tiktok.com/@sphere_experts/" target="_blank">
              <img class="image_footer" src="@/assets/images/tiktok.svg" alt="tiktok">
              <h3 class="label_social--title">TikTok</h3>
            </a>
            <a class="social_net_footer--block" href="https://twitter.com/sphere_experts?s=21&t=OA8cHau6bX38haZcQLsQEQ" target="_blank">
              <img class="image_footer" src="@/assets/images/twitter.svg" alt="twitter">
              <h3 class="label_social--title">Twitter</h3>
            </a>
          </div>
        </div>
        
        <div class="rigth_footer--block content-center">
          <a class="contetn_right--text top_margin_footer" href="/agreement">Политика обработки персональных данных</a>
          <a :href="this.$store.state.expertsBotUrl" class="w-1/3 mt-5"><img src="@/assets/images/globus.svg" alt="sphere experts image"></a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Header',
}
</script>


<style scoped>
</style>
