import { createApp, Vue } from 'vue'
import './main.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/jquery.warpdrive';

// import socketio from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';

// export const SocketInstance = socketio('https://sphere-experts.com');

// Vue.use(VueSocketIO, SocketInstance)
// .use(cors())
createApp(App).use(store).use(router).mount('#app')
