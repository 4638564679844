import { createRouter, createWebHistory } from 'vue-router'
import LandView from '../views/LandView.vue'
import BlogView from '../views/BlogView.vue'
import ArticleView from '../views/ArticleView.vue'
import FindView from '../views/FindView.vue'
import ExpertView from '../views/ExpertView.vue'
import MessengerView from '../views/MessengerView.vue'
import StatsView from '../views/StatsView.vue'
import LoginView from '../views/LoginView.vue'
import { getCookie } from '../utils'
import { DEV_ACCESS_COOKIE_NAME } from '../constants'
import bcrypt from 'bcryptjs';

const routes = [
  {
    path: '/',
    name: 'Land',
    component: LandView
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogView
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: ArticleView
  },
  {
    path: '/find',
    name: 'Find',
    component: FindView
  },
  {
    path: '/expert/:id',
    name: 'Expert',
    component: ExpertView
  },
  {
    path: '/events',
    name: 'Messenger',
    component: MessengerView
  },
  {
    path: '/stats',
    name: 'Stats',
    component: StatsView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/**
 * Проверка авторизации для dev сервера
 * @param {*} to 
 * @returns 
 */
const canUserAccess = async (to) => {
  const cookieDevValue = getCookie(DEV_ACCESS_COOKIE_NAME);
  if (cookieDevValue) {
    const result = await bcrypt.compare(process.env.VUE_APP_DEV_PASS, cookieDevValue)
    if (result) {
      return true;
    } else {
      return false;
    }
  } else return false
}

router.beforeEach(async (to) => {
  if (process.env.VUE_APP_ENV === 'development') {
    const canAccess = await canUserAccess(to)
    if (!canAccess && to.name !== 'Login') { 
      return { name: 'Login' }
    }

    if(canAccess && to.name === 'Login') {
      return { name: 'Land'}
    }
  } else {
    if(to.name === 'Login') {
      return false;
    }
  }
})

export default router
