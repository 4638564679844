<template>
  <div v-if="this.$route.name != 'Login'">
    <!-- <InfinitySpaceBackground/> -->
    <div class="app_template">
      <div class="relative">
        <Header></Header>
        <div class="styled-content">
          <router-view/>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <router-view/>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import InfinitySpaceBackground from './components/InfinitySpaceBackground.vue';
import axios from 'axios';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { setCookie, getCookie } from './utils';

import { AUTH_COOKIE_NAME } from './constants';


// const Telegram = require('node-telegram-bot-api');

export default {
  name: 'App',
  components: {
    InfinitySpaceBackground,
    Header,
    Footer
  },
  data() {
    return {
      uniqueId: null,
    };
  },
  beforeMount () {
    if (structuredClone) {
      this.$root.historyCount = structuredClone(window.history.length)
    } else {
      console.warn('You are using outdated browser')
    }
  },
  mounted() {
    this.$nextTick(function () {
      let telegramAppChatLabs = window.Telegram.WebApp;
      telegramAppChatLabs.expand();
      window.Telegram.WebApp.enableClosingConfirmation();
    })
  },
  // mounted() {
  //   console.log('Telegram');
  //   // var Telegram = require('node-telegram-bot-api').default;
  //   console.log(window.Telegram);
  //   // console.log(Telegram)
  //   let telegramAppChatLabs = window.Telegram.WebApp; //нужно получить объект window.Telegram.WebApp Телеграмма
  //   console.log(telegramAppChatLabs.isExpanded)
  //   telegramAppChatLabs.expand();
  //   console.log(telegramAppChatLabs.isExpanded)
  //   // telegramAppChatLabs.isExpanded = false;
  //   telegramAppChatLabs.onEvent('close', function() {
  //     telegramAppChatLabs.showAlert("Нет пути назад!");
  //   });
  // },
  async created() {
    const existingUniqueId = await getCookie(AUTH_COOKIE_NAME);
    if (existingUniqueId) {
      this.sendRequestToBackend(existingUniqueId);
      this.uniqueId = existingUniqueId;
    } else {
      this.generateUniqueId();
    }
    console.log('existingUniqueId : ', this.uniqueId)
  },
  methods: {
    generateUniqueId() {
      // Генерируем уникальный id
      const uniqueId = this.generateId();
      // Сохраняем его в куках
      setCookie(AUTH_COOKIE_NAME, uniqueId);
      this.uniqueId = uniqueId;
      // Отправляем запрос на бекенд
      this.sendRequestToBackend(uniqueId);
    },
    generateId() {
      const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      let uniqueId = "";
      const idLength = 12;
      for (let i = 0; i < idLength; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueId += characters[randomIndex];
      }
      return uniqueId;
    },
    sendRequestToBackend(uniqueId) {
      console.log('sendRequestToBackend', uniqueId)
      axios.get(`${this.$store.state.base_url}/api/auth/`, {
        params: {
          uid: uniqueId
        }
      }).then(response => {
        if (response.status === 200) {
          console.log(response.data);
          this.$store.state.authorized = true;
          this.$store.state.user = response.data.data;
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/*html {
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none;
  -webkit-size-adjust: 100%;
}

body {
  overflow: hidden;
}

.styled-content {
  overflow-y: auto;
  overflow-x: hidden;
*/
/*}*/

/*body {
    touch-action: none !important;
}*/

.container--lg {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
}

.styled-content {
  padding-top: 80px;
}

.app_template {
  position: absolute;
  overflow: auto;
  z-index: 100;
  top: 0;
  left: 1%;
  width: 98%;
}

.desktop-view {
  display: flex;
}

.mobile-view {
  display: none;
}

* {
  box-sizing: border-box;
  font-family: 'Source Sans Pro';
  font-style: normal;
}

a {
  text-decoration: none;
  color: var(--custom-blue);
}


body {
  margin: 0;
  padding: 0;
  background: var(--dark-background);
}

.dark body {
  background: var(--dark-background-theme);
}

/* Продолжение CSS с добавлением переменных для цветов и поддержкой тёмной темы */

/* Общие стили */
p, label, h1, h2, h3, h4, h5, h6, small, span {
  cursor: default;
  color: var(--light-text);
  line-height: 100%;
  margin: 0;
  padding: 0;
}

.dark label, .dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6, .dark small, .dark span {
  color: var(--light-text-theme);
}

.container {
  width: 1140px;
  margin: 0 auto;
}

/* Header */
.header_jedels--block {
  width: 100%;
  height: 100%;
  position: relative;
}

.header--menu {
  padding-top: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label_header {
  margin: 0;
  font-family: 'Russo One', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  color: var(--mid-gray);
}

.dark .label_header {
  /* color: #368CCC; */
}

.middle_menu_header--block {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.header_text_middle_menu {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--grey-text);
  border: none;
  padding-bottom: 2px;
  text-shadow: 0px 2px 10px var(--text-shadow-color);
}

.dark .header_text_middle_menu {
  color: var(--grey-text-theme);
  text-shadow: 1px 2px 5px var(--text-shadow-color-theme);
}

.header_text_middle_menu:hover, .header_text_middle_menu.active {
  padding-bottom: 0;
  border-bottom: 2px solid var(--link-hover);
}

.dark .header_text_middle_menu:hover, .dark .header_text_middle_menu.active {
  border-bottom-color: var(--link-hover-theme);
}

.number_header {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--mid-gray);
}

.dark .number_header {
  color: var(--mid-gray-theme);
}

/* Footer */
.footer_jedels {
  width: 100%;
  height: 100%;
  background: var(--dark-background);
}

.dark .footer_jedels {
  background: var(--dark-background-theme);
}

.footer--menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 0;
  width: 100%;
}

.logo_footer--block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.data_creation {
  font-size: 16px;
  line-height: 20px;
  color: var(--light-text);
}

.dark .data_creation {
  color: var(--light-text-theme);
}

.middle_socail_footer--blocks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}

.social_footer--column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
}

.social_footer--column--big {
  gap: 20px;
}

.footer-background--block {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  padding: 15px 20px;
}

.dark .footer-background--block {
  background: var(--transparent-black-theme);
}
.social_net_footer--block {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  transition: 0.3s;
}

.social_net_footer--block:hover img {
  background: var(--custom-blue);
  transition: 0.3s;
}

.dark .social_net_footer--block:hover img {
  background: var(--custom-blue-theme);
}

.social_net_footer--block:hover h3 {
  color: var(--custom-blue);
  transition: 0.3s;
}

.dark .social_net_footer--block:hover h3 {
  color: var(--custom-blue-theme);
}

.label_social--title {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--light-text);
  transition: 0.3s;
  white-space: nowrap;
}

.dark .label_social--title:hover {
  color: var(--light-text-theme);
}

.label_jedels {
  font-family: 'Russo One', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--mid-gray);
  margin-top: 10px;
}

.label_jedels a {
  color: var(--mid-gray);
}

.dark .label_jedels, .dark .label_jedels a {
  color: var(--mid-gray-theme);
}

.rigth_footer--block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contetn_right--text {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.dark .contetn_right--text {
  color: var(--light-text-theme);
}

.contetn_right--text:hover {
  border-bottom: 2px solid var(--custom-blue);
  margin-bottom: -2px;
}

.dark .contetn_right--text:hover {
  border-bottom-color: var(--custom-blue-theme);
}

.image_footer {
  margin-right: 10px;
  border-radius: 50%;
}

.top_margin_footer {
  margin-top: 10px;
}

/* //basic content */

.service--direction {
  width: 100%;
  height: 100%;
  position: relative;
}

.type_of_service {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 75px;
  line-height: 81px;
  color: var(--custom-blue);
}

.dark .type_of_service {
  color: var(--custom-blue-theme);
}

.black_type_color {
  color: var(--transparent-black);
}

.dark .black_type_color {
  color: var(--transparent-black-theme);
}

.content_information--slide {
  width: 100%;
  height: 326px;
  position: relative;
  display: flex;
  flex-direction: row;
  position: relative;
}

.blocks_infotmation--development {
  height: 326px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project_from_scratch--left {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 45%;
  height: 100%;
  background: var(--light-background);
  border-radius: 0px 20px 20px 0px;
}

.hand_top {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 300px;
  animation: hand 2.7s ease-in-out infinite;
  transform-origin: bottom center;
}

@keyframes hand {
  0% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-15deg);
  }
}

.text_project_from {
  margin: 0;
  padding-top: 17px;
  padding-right: 32px;
  width: 68%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: var(--mid-gray);
}

.color_text_project {
  color: var(--transparent-black);
}

.dark .color_text_project {
  color: var(--transparent-black-theme);
}

.button_top--block {
  padding-top: 30px;
  padding-right: 32px;
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 58%;
  align-items: flex-start;
}

.promotion--button {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  border: none;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: var(--pure-white);
  padding: 3px 23px;
  background: var(--custom-blue);
  border-radius: 20px;
}

.dark .promotion--button {
  background: var(--custom-blue-theme);
}

.flutter_style {
  margin-top: 20px;
  padding: 3px 28px;
  background: #04428A;
  margin-left: 40%;
  white-space: nowrap;
}

.react_style {
  margin-top: 20px;
  padding: 3px 36px;
  background: #02224B;
  margin-left: 10%;
}

.cripto_case-block {
  width: 27%;
  height: 100%;
  background: var(--light-background);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.love_jedels--right--block {
  position: relative;
  background: #191919;
  border-radius: 20px;
  width: 26%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dark .love_jedels--right--block {
  background: #19173D;
}

.love_text_jedels {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--pure-white);
  padding: 0 11px;
  padding-top: 15px;
}

.dark .love_text_jedels {
  color: #ffffff;
}

.text_love_information {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 108.5%;
  display: flex;
  align-items: center;
  color: var(--transparent-black);
  padding-top: 38px;
  padding-left: 25px;
  width: 55%;
}

.dark .text_love_information {
  color: var(--transparent-black-theme);
}

.jtj {
  top: -27%;
  position: absolute;
  right: -18%;
}

.dots_blocks_content {
  width: 100%;
  height: 100%;
}

.block_slider_dot--block {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 15px;
  height: 15px;
  background: rgba(141, 141, 145, 0.76);
  border-radius: 50px;
}

.dot_big {
  width: 36px;
  border-radius: 30px;
}

.content_dots--block {
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button_connection--block {
  padding-top: 7px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.contact_us--button {
  margin: 0;
  border: none;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: var(--pure-white);
  padding: 14px 40px;
  background: var(--custom-blue);
  border-radius: 10px;
}

.dark .contact_us--button {
  background: var(--custom-blue-theme);
}

.white--block {
  position: absolute;
  left: 0;
  width: 30%;
  height: 326px;
  background: var(--light-background);
}

.dark .white--block {
  background: var(--dark-background-theme);
}

.arrow_next_top--slide {
  position: absolute;
  right: -5%;
  top: 40%;
  bottom: 30%;
}

.services--block {
  width: 100%;
  height: 100%;
}

/* услуги */

.service--text {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.4);
  padding-top: 60px;
  padding-bottom: 17px;
  background: none !important;
}

.types_of_services--block {
  width: 100%;
  height: fit-content;
  padding-top: 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 17px;
}

.top_left_line--blocks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 41%;
}

.blockchaine--block {
  background: var(--custom-blue);
  border-radius: 15px;
  position: relative;
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.dark .blockchaine--block {
  background: var(--custom-blue-theme);
}

.blockchaine--text {
  margin: 0;
  padding: 23px 18px;
  width: 60%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: right;
  color: var(--pure-white);
}

.dark .blockchaine--text {
  color: var(--transparent-black-theme);
}

.blue_block {
  position: absolute;
  bottom: 0;
  left: 0;
}

.linux--block {
  height: 100%;
  width: 57%;
  position: relative;
  background: var(--light-background);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dark .linux--block {
  background: var(--dark-background-theme);
}

.linux--text {
  margin: 0;
  padding: 18px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: right;
  color: var(--transparent-black);
}

.dark .linux--text {
  color: var(--transparent-black-theme);
}


.corner {
  width: 115px;
  height: 100px;
  position: absolute;
  left: 5%;
  top: 5%;
}

.bottom_mobile_app--block {
  width: 100%;
  height: 258px;
  background: var(--mid-gray);
  border-radius: 15px;
}

.dark .bottom_mobile_app--block {
  background: var(--mid-gray-theme);
}

.top_menu_mobile_app--block {
  width: 100%;
  display: flex;
  padding: 18px;
  flex-direction: row;
}

.top_text_left_mobile_app--title {
  margin: 0;
  width: 68%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #FFFFFF;
}

.top_text_right_mobile_app--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: right;
  color: #FFFFFF;
}

.style_color_top_text {
  color: #79C5FC;
}

.information_mobile_service--text {
  margin: 0;
  padding-left: 18px;
  width: 88%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
}

.content_information_plus--block {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.more--button {
  position: relative;
  background: none;
  border: none;
}

.more_button--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: #FFFFFF;
  padding-right: 48px;
}

.corner_grey {
  position: absolute;
  bottom: 2%;
  right: 15%;
}

.middle_service--block {
  flex-basis: 60%;
  height: 100%;
  position: relative;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.name_middle_block--title {
  margin: 0;
  padding: 18px 24px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 96.2%;
  color: #000000;
}

.development {
  color: #0B7EE9;
}

.information_middle--text {
  margin: 0;
  padding: 0 18px;
  padding-top: 20px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 124.7%;
  color: #000000;
}

.price_projects--text {
  margin: 0;
  padding: 0 18px;
  padding-top: 11px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 124.7%;
  color: #0B7EE9;
}

.circle_grey {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 0px 15px;
}

.circle_blue {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0px 0px 15px 0px;
}

.middle_top_plus {
  margin-top: 115px;
}

.right_service--child {
  width: 23%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.site_creation--text {
  position: relative;
  width: 100%;
  height: 240px;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.site_text_creation--title {
  margin: 0;
  z-index: 2;
  padding: 19px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #0B7EE9;
}

.zatmenie_white {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.telegram--block {
  position: relative;
  width: 100%;
  height: 209px;
  align-items: flex-end;
  display: flex;
  border-radius: 15px;
  background: #0B7EE9;
}

.telegram--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #FFFFFF;
  padding-left: 48px;
  padding-bottom: 21px;
}

.telegram_airplane {
  position: absolute;
  top: 10%;
  right: 10%;
}

.content_show_block-btn {
  width: 100%;
  padding-top: 17px;
  display: flex;
  justify-content: center;
}

.show_more--button {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  border: none;
  color: #000000;
  background: #FFFFFF;
  border-radius: 30px;
  padding: 7px 50px;
}

.case_name_top {
  padding-top: 70px;
  padding-bottom: 15px;
}

.cases--block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.king_durian--block {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 34px 37px;
  background: #000000;
}

.cases_bottom--block {
  width: 100%;
  height: 100%;
  gap: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.heat_map--block {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 49%;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;
}

.heat_map--title {
  width: 100%;
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 41px;
  color: #2E95EF;
}

.black_style--text {
  color: #191919;
}

.heat_map--text {
  width: 100%;
  margin: 0;
  padding-top: 17px;
  margin-bottom: 220px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #616161;
  width: 80%;
}

.heat_map-information_blue {
  color: #1346B4;
}

.detail--button {
  margin: 0;
  border: none;
  text-align: center;
  margin: 19px 57px;
  padding: 5px 36px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  background: #0B7EE9;
  border-radius: 20px;
  z-index: 3;
  transition: 0.4s;
}

.detail--button:hover {
  background: #0653AA;
  transition: 0.4s;
}

.wave_blue {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 15px 0px;
}

.mac_studio {
  bottom: 12px;
  position: absolute;
  right: 0%;
}

.event_search--block {
  width: 49%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 20px;
  padding: 24px;
}

.event_searh--title {
  margin: 0;
  margin-top: -40px;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #0B7EE9;
}

.event_searh--text_blue {
  color: #14C9FD;
}

.event_searh--text {
  margin: 0;
  width: 87%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 134.2%;
  color: #616161;
  margin-top: 9px;
}

.content_button_search--block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search--btn {
  margin: 0;
}


.team_block--black {
  display: none;
/*  background: #191919;*/
  width: 100%;
  position: relative;
}

.team_block--black {
  display: flex;
}


.dream_team--block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}

.middle_team--image {
  margin-top: 32px;
  margin-bottom: 26px;
}

.jadels_we--blue {
  color: #2F95EF;
}

.advantages_blue--block {
  background: #0B7EE9;
  width: 100%;
}

.human_quality--block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 39px;
}

.kind_of_quality--block {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.charter {
  width: 100px;
  height: 100px;
}

.quality--title {
  margin: 0;
  padding-top: 10px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.quality--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
}

.contact--block {
  display: flex;
  flex-direction: column;
}

.contact_h1--text {
  padding-top: 49px;
  padding-bottom: 13px;
}

.content_information--block {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 57px;
}

.contact_blue_left--block {
  background: #0B7EE9;
  border-radius: 15px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.name_block_blue_left--title {
  margin: 0;
  padding-top: 24px;
  padding-left: 42px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.information_line_contact--block {
  width: 100%;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 42px;
  padding-right: 34px;
}

.left_information--part {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 32%;
}

.tube {
  width: 20px;
  height: 19px;
  margin-right: 7px;
}

.left_information--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
}

.right_information--part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 65%;
}

.right_information--button {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  border: none;
  line-height: 29px;
  color: #FFFFFF;
  padding: 7px 14px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
}

.top-right_btn {
  margin-top: 16px;
}

.envelope {
  width: 18px;
  height: 14px;
  margin-right: 9px;
}

.text_items {
  align-items: center;
}

.telegram_blue {
  width: 22px;
  height: 19px;
  margin-right: 10px;
}

.bottom_information_part {
  padding-bottom: 26px;
}

.contat_white_right--block {
  width: 58%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 15px;
}

.name_white_right--title {
  margin: 0;
  padding-top: 21px;
  padding-left: 46px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.fio--block {
  margin: 0;
  padding: 0 46px;
  padding-top: 49px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name--block {
  display: flex;
  flex-direction: row;
}

.name--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.content--input {
  border: none;
  margin-left: 5px;
  outline: none;
  border-bottom: 2px solid #616161;
}

.number--block {
  padding-top: 35px;
  display: flex;
  flex-direction: row;
  padding-left: 46px;
}

.number_style--input {
  border-bottom: none;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #616161;
}

.flex-direction--style {
  flex-direction: column;
}

.width_content_input {
  width: 90%;
}

.file_upload--block {
  padding: 0 45px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: flex-start;
}

.text--file_upload {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #616161;
  width: 55%;
}

.btn--file_upload {
  margin: 0;
  border: none;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  background: #0B7EE9;
  border-radius: 30px;
  padding: 0 18px;
  padding-top: 4px;
  padding-bottom: 10px;
}

.btn--block_send-block {
  width: 100%;
  padding-top: 39px;
  padding-bottom: 35px;
  display: flex;
  justify-content: center;
}

.btn--block_send {
  margin: 0;
  border: none;
  width: 80%;
  padding-top: 8px;
  padding-bottom: 12px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  background: #0B7EE9;
  border-radius: 20px;
}

.black_line {
  position: absolute;
  width: 78px;
  top: 6%;
  left: 37%;
}

.mobile_app_style--block {
  align-items: flex-start;
  justify-content: flex-start;
}

.h1_mobile_app-text {
  text-align: left;
  width: 78%;
  padding-top: 20px;
}

.grey-triangle {
  position: absolute;
  top: 0;
  right: 0;
}

.mobile_app--button {
  padding-top: 40px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-start;
}

.black_color--button {
  background: #000000;
}

.top_content_mobile--button {
  margin-top: 16px;
}

.flutter_style_mobile_app--button {
  margin-left: 40%;
}

.pro_apple {
  position: absolute;
  right: 0;
  bottom: 0;
}

.mobile_app_style_width--block {
  width: 49.5%;
}

.grey_color_block {
  background: #8C8E8C;
}

.keyboard {
  position: absolute;
  bottom: 10%;
  right: 5%;
}

.blue_triangle {
  position: absolute;
  bottom: 0;
  left: 0;
}

.modern_methods--block {
  align-items: flex-end;
}

.modern_methods--text {
  width: auto;
  padding-top: 17px;
  padding-left: 0;
  padding-right: 19px;
}

.modern_metods_top--block {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
}

.modern_metods_top--button {
  background: #FFFFFF;
  border-radius: 30px;
  padding: 10px 17px;
  border: none;
  width: 65%;
  text-align: end;
  margin-left: 41px;
  position: relative;
}

.modern_metods_top_button--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}

.loupe {
  position: absolute;
  bottom: 20%;
  left: 5%;
}

.black_blockchaine--block {
  background: #000000;
  border-radius: 0px 15px 15px 0px;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.cube_blue {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 150px;
}

.black_blockchaine--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 113.18%;
  color: #FFFFFF;
  margin-top: 31px;
  width: 70%;
  padding-right: 30px;
}

.color_text_black_blockchaine {
  color: #0B7EE9;
}

.noda--block {
  width: 41.5%;
  display: flex;
  margin-left: 20px;
  background: #FFFFFF;
  border-radius: 15px;
  height: 100%;
  position: relative;
  justify-content: flex-end;
}

.computer_noda {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.noda--title {
  margin: 0;
  width: 70%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 113.18%;
  text-align: right;
  color: #000000;
  padding: 24px 25px;
}

.web_three--block {
  width: 42%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 15px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blockchaine_content_justify--block {
  justify-content: flex-start;
}

.web_three--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 113.18%;
  color: #616161;
  padding-top: 28px;
  padding-left: 26px;
  text-align: left;
  width: 80%;
}

.color_text_web_three_blockchaine {
  color: #000000;
}

.web_three_button--block {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  align-items: flex-start;
}

.web_three--button {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 113.18%;
  color: #FFFFFF;
  padding: 6px 18px;
  background: #E4E4E4;
  border-radius: 30px;
  border: none;
}

.cobweb_style--button {
  padding: 6px 22px;
  background: #BCB1B1;
  margin-top: 15px;
  margin-left: 30%;
  white-space: nowrap;
}

.liberty_style--button {
  background: #888686;
  padding: 6px 23px;
  margin-top: 15px;
}

.openness_style--button {
  padding: 6px 21px;
  background: #787676;
  margin-top: -15px;
  margin-left: 70%;
}

.inteligence_style--button {
  padding: 6px 20px;
  margin-top: 15px;
  background: #333333;
}

.key_development--block {
  width: 92%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 0px 15px 15px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.gold_key {
  position: absolute;
  bottom: 15%;
  left: 0%;
  width: 250px;
  height: 240px;
}

.key_development--title {
  text-align: left;
}

.project--block {
  background: #0B7EE9;
  border-radius: 15px;
  width: 80%;
  height: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.white_block {
  margin: 28px;
  width: 450px;
  margin-bottom: 11px;
  height: 200px;
}

.project_white--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 108.5%;
  text-align: right;
  color: #FFFFFF;
  padding-right: 29px;
  width: 60%;
}

.color_black_need--block {
  background: #000000;
  align-items: flex-start;
}

.ipad {
  position: absolute;
  bottom: 2%;
  right: 0;
}

.black_need--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
  padding-left: 110px;
  padding-bottom: 10px;
}

.top_content_black_need--title {
  padding-top: 18px;
}

.slider_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.slider_items {
  display: flex;
  transition: 0.5s ease;
  min-width: 100%;
}

.slider_item {
  position: relative;
  max-width: 100%;
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
}
  
  /* .slider_item img {
  width: 100%;
  height: 100%;
  } */
  
.navigation_block {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  z-index: 2 !important;
}

.key_button {
  position: absolute;
  top: 50%;
  transform: translateY(50%);
}

#reviewsNext {
  right: 5%;
  border: none;
  background: none;
}

#reviewsPreview {
  left: 5%;
  border: none;
  background: none;
}
  



  /* #myBth {
  border: none;
  background: none;
  } */


.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #616161;
  justify-content: flex-end;
  text-align: start;
}

.modal-content {
  background-color: #fefefe;
  margin: 50% auto;
  width: 70%;
  position: fixed;
  flex-direction: column;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.close {
  position: absolute;
  right: 2%;
  top: 2%;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.mobile_app_class--title {
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #000000;
  margin: 0;
  padding-top: 40px;
  padding-left: 72px;
}

.hr_mobile_app--line {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_app_class--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  text-align: right;
  color: #000000;
  padding-left: 54px;
}

.blue_color_amount {
  color: #0B7EE9;
}

.part_information--text {
  margin: 0;
  padding-bottom: 30px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  padding-left: 40px;
  width: 45%;
  text-align: left;
}

.bottom_margin--text {
  padding-bottom: 40px;
}

.promodal {
  position: absolute;
  right: 5%;
  top: 12.6%;
}

.write_to_us--text {
  margin: 0;
  position: absolute;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 141.2%;
  color: #616161;
  right: 5%;
  bottom: 22%;
}

.blue_contact--button {
  position: absolute;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF !important;
  margin: 0;
  border: none;
  padding: 15px 40px;
  background: #0B7EE9;
  border-radius: 10px;
  right: 5%;
  bottom: 12%;
}

.jadels_modal {
  flex-direction: row;
  width: 90%;
}

.blue_left_part--block {
  background: #0B7EE9;
  border-radius: 15px 0px 0px 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.blue_left_part--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
  padding: 6px 19px;
  background: #000000;
  border-radius: 15px;
  text-align: left;
  margin: 30px 40px;
  margin-bottom: 16px;
}

.blue_left_part--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  padding-left: 58px;
  padding-right: 35px;
  padding-bottom: 45px;
  text-align: left;
}

.logo_company--text {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 77px;
  color: rgba(255, 255, 255, 0.15);
  padding-bottom: 64px;
}

.left_margin_mobile {
  padding-left: 58px;
}

.right_white_part--block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.desc_style--block {
  align-items: flex-start;
}

.modal_file_upload_style--text {
  text-align: left;
}

.modal_btn_send--block {
  padding-top: 57px;
  padding-bottom: 60px;
}

.content_left_footer--block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.website--style {
  width: 30%;
}

.not_found--style {
  width: 70%;
}

.quality_text--block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prokrutka {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  overflow: auto;
  scrollbar-width: thin;
/*  scrollbar-color: #0B7EE9;*/
}

/*.prokrutka::-webkit-scrollbar {
  background-color: #f9f9fd;
}*/

/*.prokrutka::-webkit-scrollbar-thumb {
  background-color: #0B7EE9;
}*/

.team_person--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
  padding-bottom: 22px;
}

.team_person--block {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.social_header--block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: 0.3s;
}

.social_header--block a:hover {
  transform: scale(1.08);
  transition: 0.3s;
}

.header_telegram {
  width: 33px;
  height: 34px;
  margin-left: 9px;
}

.bold_style--text {
  font-weight: 700;
}

.iphone_pro {
  width: calc(100% + 10px);
  margin-top: -24px;
  margin-left: -24px;
  border-radius: 20px 0px 0px 0px;
}

.capability--title {
  margin: 0;
  padding-top: 17px;
  padding-left: 20px;
  padding-right: 17px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #0B7EE9;
}

.cripto_case--block {
  width: 27%;
  background: var(--pure-white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.dark .cripto_case--block { 
  background: var(--pure-white-theme);
}

.capability--text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 108.5%;
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0;
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 17px;
}

.top_style_capability--text {
  padding-top: 15px;
}

.globus {
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
}

.data_creation {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #616161;
  margin: 0;
}

.content_link_bot--button {
  display: flex;
  border: none;
  flex-direction: row;
  background: #0B7EE9;
  border-radius: 10px;
  padding: 11px 66px;
  z-index: 2;
  transition: 0.3s;
}

.content_link_bot--button:hover {
  background: #0653AA;
  transition: 0.3s;
}

.content_link_bot_button--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF !important;
  padding-left: 26px;
}

.community_style--block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 34px;
}

.wave_grey {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.for_an_expert--block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #191919;
  width: 45%;
  border-radius: 20px 0px 100px;
  padding-top: 20px;
  padding-bottom: 26px;
  padding-left: 35px;
  padding-right: 78px;
}

.for_an_expert--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 108.5%;
  color: #FFFFFF;
  white-space: nowrap;
  padding-left: 19px;
}

.advantages_expert--block {
  padding: 0 35px;
  margin-top: 23px;
  display: flex;
  flex-direction: column;
}

.arrow_blue-image {
  width: 26px;
  height: 28px;
  margin-left: 5px;
}

.advantages_exper-title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 108.5%;
  color: #000000;
  padding-top: 19px;
}

.right_service_content--blocks {
  flex-basis: 40%;
    height: 100%;
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}


.black_advantages_bold-text {
  font-weight: 700;
}

.bottom_padding_advantages_expert--text {
  padding-bottom: 42px;
}

.right_service--child {
  width: 100%;
  background: #0B7EE9;
  border-radius: 20px;
}

.white_for_an_expert {
  background: #FFFFFF;
  width: 90%;
}

.black_for_an_expert--title {
  color: #191919;
}

.white_advantages_exper-title {
  color: #FFFFFF;
}

.bottom_padding_expert--block {
  padding-bottom: 30px;
}

.buttons_advantages_expert--block {
  width: 100%;
}

.buttons_advantages_expert--child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  gap: 12px;
  margin-top: 12px;
  width: 100%;
}


.buttons_advantages--button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #FFFFFF;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: 1s;
}

.buttons_advantages--button:hover {
  background: #FFFFFF;
  outline: 1px solid #0B7EE9;
  box-shadow: 0px 4px 7px rgba(6, 93, 174, 0.27);
  transition: 1s;
}

.buttons_advantages--title {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 108.5%;
  color: #000000;
  margin-left: 9px;
}

.buttons_advantages--emoji {
  width: 25px;
  height: 25px;
}


.maps_sphere--block {
  padding-top: 48px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
}

.part_maps_sphere--block {
  display: flex;
  z-index: 1;
  flex-direction: column;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.maps_telegram--first {
  animation: spin 2.5s linear forwards infinite;
}

.maps_telegram--second {
  animation: spin 3s linear forwards infinite;
}

.maps_telegram--third {
  animation: spin 4s linear forwards infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.maps_telegram--text {
  margin: 0;
  padding-top: 20px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
/*  font-size: 22px;*/
  white-space: nowrap;
  line-height: 27px;
  color: #ffffff;
  border: none;
}

.dark .maps_telegram--text {
  color: #777777;
}

.black_maps_line {
  position: absolute;
  top: 50%;
  z-index: 0;
}

.comment_content_people--blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.comment_content_people--block {
  position: relative;
    min-width: 345px;
    max-width: 345px;
  height: 500px;
  overflow-y: scroll;
  padding: 24px 14px;
  background: #FFFFFF;
  border-radius: 25px;
  margin: 0 10px;
  margin-top: 74px;
/*  margin-bottom: 100px;*/
}

.comment_content_people--block::-webkit-scrollbar {
  width: 5px;
}

.comment_content_people--block::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.comment_content_people--block::-webkit-scrollbar-thumb {
  background-color: #0B7EE9;
  outline: 1px solid #0B7EE9;
  border-radius: 3px;
}

.top_comment_content_people--block {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.comment_content_people_blue--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 108.5%;
  color: #0B7EE9;
}

.comment_content_people_blue--title a {
  color: #0B7EE9;
}

.charter_laptop_man {
  width: 22px;
  height: 26px;
  margin-left: 10px;
}

.comment_content_people_black--title {
  margin: 0;
  padding-top: 3px;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 108.5%;
  color: #191919;
}

.comment_content_people_expert--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 108.5%;
  color: #191919;
}

.expert_laptop_man {
  width: 18px;
  height: 20px;
}

.expert_top_comment_content_people {
  margin-top: 14px;
}

.part_sphere {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 108.5%;
  color: #191919;
  padding-top: 6px;
  padding-left: 10px;
}

.text_part_quality {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 108.5%;
  color: #191919;
  padding-left: 15px;
  padding-top: 2px;
  list-style-type: none;
}

.text_part_quality:before {
  content: '•';
  margin-right: 15px;
}

.back_comment_content_people {
  position: absolute;
  background: #FFFFFF;
  z-index: 150;
  left: 6%;
  border-radius: 15px;
  width: 49px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  border: none;
  box-shadow: 0px 2px 9px 1px rgba(16, 109, 196, 0.15);
}

.next_comment_content_people {
  position: absolute;
  background: #FFFFFF;
  z-index: 150;
  right: 6%;
  border-radius: 15px;
  width: 49px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  border: none;
  box-shadow: 0px 2px 9px 1px rgba(16, 109, 196, 0.15);
}

.arrow_blue_back {
  z-index: 6;
}

.mobile_view_style {
  display: none;
}

.go_to_bot {
  display: none;
}

.mobile_style_slider_wrapper {
  display: none;
}

.desktop_style_image {
  display: none;
}

.desktop_style_iphone_pro {
  display: block;
}

.content_information_blog--block * {
  box-sizing: border-box;
}

.article--block {
  width: 100%;
  margin-bottom: 30px;
}

.content_information_blog--block {
  width: 100%;
  position: relative;
  height: 570px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  gap: 10px;
}

.left_content_blog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background: #FFFFFF;
  border-radius: 20px;
  box-sizing: border-box;
}


.article--main_photo--block {
  position: relative;
  display: flex;
  justify-content: center;
  height: -webkit-fill-available;
  overflow-y: hidden;
}

.article--main_photo--block > img {
  position: absolute;
  top: 0;
/*  height: 100%;*/
  width: 100%;
  border-radius: 20px
}

.left_content_blog--content {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-beween;
  position: relative;
  padding: 20px;
  gap: 15px;
}

.left_content_blog--title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 108.5%;
  color: #000000;
  margin: 0;
}

.left_content_blog--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 108.5%;
  color: #616161;
}

.read  {
  /*position: absolute;
  right: 5%;
  bottom: 5%;*/
  width: 100%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 108.5%;
  text-decoration-line: underline;
  color: #0B7EE9;
  margin: 0;
  text-align: right;
  margin-top: -10px;
}

.content_information_blog--block h3 {
  font-weight: 700;
  font-size: 26px;
  line-height: 108.5%;
  color: #000000;
}

.content_information_blog--block h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 108.5%;
  color: #616161;
}

.content_information_blog--block h4 * {
  margin: 0;
}

.right_content_blog--blocks {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
    justify-content: space-between;
  gap: 10px;
}

.right_content_blog--block {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border-radius: 20px;
}


.lamp--block {
  background: #0B7EE9;
  border-radius: 20px;
  padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lamp--block--photo {
  width: 65px;
}

.lamp--block--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 65px;
  line-height: 108.5%;
  color: #000000;
}

.right_blog--block {
  flex: 5 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 15px;
  padding: 15px;
}

.right_blog--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 108.5%;
  color: #000000;
}

.right_blog--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 108.5%;
  color: #616161;
}

.right_blog-style {
  bottom: 20%;
}

.love_padding {
  padding: 44px 35px;
}

.top_right_content_blog {
  margin-top: 11px;
}

.blog_style--text {
  font-weight: 700;
}

.blog_style_type_of_service {
  font-size: 55px;
}

.style_content_mobile_blog {
  padding-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
}

.modal_style_blog--block {
  justify-content: flex-end;
  padding-top: 0px;
  margin-top: -25px;
}

.top_blog_content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.map_blog {
  width: 25%;
  background: #FFFFFF;
  border-radius: 100px;
  height: 39px;
  border: none;
}

.top_blog_content > img {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 15px;
}

.love_blog--button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background: #FFFFFF;
  border-radius: 100px;
  height: 39px;
  margin-left: 10px;
  border: none;
  width: 8%;
}

.love_blog--title {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  padding-left: 10px;
  font-size: 18px;
  line-height: 108.5%;
  color: #FFFFFF;
}

.arrow_blog_grey_telegram {
  margin-left: 11px;
}

.width_left_content_blog {
  width: 49%;
}

.data_time--text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 108.5%;
  color: #616161;
  margin: 0;
  padding-left: 25px;
  padding-bottom: 25px;
}

.bottom_padding_left_content_blog {
  padding-bottom: 12px;
}

.bottom_content_information_blog {
  padding-bottom: 139px;
}

.absolute_circle {
  background: #FFFFFF;
  border-radius: 70px;
  position: absolute;
  bottom: -5%;
  right: -2%;
}

.absolute_circle-title {
  font-family: 'Russo One';
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 108.5%;
  color: #FFFFFF;
  padding: 5px;
}

.love_absolute_circle {
  padding: 10px 6px 5px 6px;
  z-index: 3;
}

.lamp_absolute_circle {
  padding: 7px 9px 8px 9px;
}

.time_absolute_circle {
  padding: 7px 6px 8px 6px;
}

.read_button_style {
  right: 8%;
}

.left_article--block {
  width: 65%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 20px;
  position: relative;
  margin-bottom: 100px;
}

.left_article--title {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 108.5%;
  color: #000000;
  padding-top: 29px;
  padding-left: 42px;
}

.data_blog_article {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 108.5%;
  color: #616161;
  padding-top: 10px;
  padding-left: 42px;
}

.left_article--text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 129%;
  color: #000000;
  margin: 0;
  padding-top: 34px;
  padding-left: 42px;
  padding-right: 36px;
}

.bottom_left_article {
  padding-bottom: 63px;
}

.absolute_left_article--text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 108.5%;
  color: #616161;
  margin: 0;
  position: absolute;
  right: 5%;
  bottom: 2%;
}

.absolute_circle--article {
  bottom: -2%;
}

.right_article--block {
  width: 33%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.top_right_article--block {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.width_map_blog { 
  width: 100%;
}

.width_love_blog--button {
  width: 25%;
  position: relative;
}

.lamp--block--title_artile {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 108.5%;
  color: #000000;
  padding: 39px 11px;
}

.color_lamp--block {
  margin: 0;
  color: #FFFFFF;
  padding: 39px 6px;
}

.left_article--title {
  font-size: 22px;
  padding-top: 20px;
  padding-left: 20px;
}

.data_blog_article {
  padding-left: 20px;
  padding-top: 12px;
}

.left_article--text {
  padding-top: 13px;
  padding-left: 20px;
}

.mobile_style_left_article {
  display: none;
}

.article_right_blog {
  bottom: 10%;
}

.love_lamp--block {
  background: #191919;
}


.moda {
  display: none; /* Скрыто по умолчанию */
  position: fixed; /* Оставаться на месте */
  z-index: 1; /* Сидеть на вершине */
  top: 0;
  width: 100%; /* Полная ширина */
  height: 100%; /* Полная высота */
  overflow: auto; /* Включите прокрутку, если это необходимо */
  justify-content: flex-end;
  text-align: start;
}
  
  /* Модальное содержание/коробка */
.moda-content {
  margin: 10% auto; /* 15% сверху и по центру */
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(25, 25, 25, 0.25);
  border-radius: 20px;
  backdrop-filter: blur(22.5px);
  border-radius: 15px;
  width: 20%; /* Может быть больше или меньше, в зависимости от размера экрана */
  position: absolute;
  right: 7%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.moda_block_content {
  display: flex;
  flex-direction: row;
}

.smile--text {
  margin: 0;
  font-family: 'Russo One';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 108.5%;
  color: #FFFFFF;
}

.smile--title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 108.5%;
  color: #000000;
  margin: 0;
  padding-left: 11px;
}

.top_moda_block {
  padding-top: 23px;
}

.lamp_smile {
  padding-left: 5px;
}

.modaArticle {
  display: none; /* Скрыто по умолчанию */
  position: fixed; /* Оставаться на месте */
  z-index: 1; /* Сидеть на вершине */
  top: 0;
  width: 100%; /* Полная ширина */
  height: 100%; /* Полная высота */
  overflow: auto; /* Включите прокрутку, если это необходимо */
  justify-content: flex-end;
  text-align: start;
}
  
  /* Модальное содержание/коробка */
.moda-contentArticle {
  margin: 10% auto; /* 15% сверху и по центру */
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(25, 25, 25, 0.25);
  border-radius: 20px;
  backdrop-filter: blur(22.5px);
  border-radius: 15px;
  width: 20%; /* Может быть больше или меньше, в зависимости от размера экрана */
  position: absolute;
  right: 7%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content_article {
  right: 15.2%;
  margin: 7% auto;
}



@media (min-width: 701px) {
  .feedback {
    display: block !important;
    transition: transform 0.5s;
  }
}

@media screen and (max-width: 700px) {

  .desktop-view {
    display: none !important;
  }

  .buttons_advantages--title {
    font-size: 14px;
  }

  .mobile-view {
    display: block;
  }

  .mobile_block {
    display: block;
  }

  .project--block {
    display: none;
  }

  .mobile_view_style {
    display: block;
  }

  .header_jedels--block {
    width: 100%;
    overflow-x: hidden;
    z-index: 10;
    position: fixed;
    top: 0;
    height: auto;
    background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(8 0 188));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .middle_menu_header--block {
    display: none;
  }

  .number_header {
    display: none;
  }

  .header--menu {
    padding-top: 0;
  }

  .dot_mobile_top--block {
    overflow-x: hidden;
    margin: 0 auto;
    padding: 0 19px;
    width: auto !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 22px;
  }

  .div_slide_part {
    width: 50px;
    height: 20px;
    border: 3px solid #616161;
    border-radius: 3px;
  }

  .blue_block_part {
    background: #0B7EE9;
    border: 3px solid #0B7EE9;
    border-radius: 3px;
  }

  .slide_sites--block {
    margin-top: 40px;
    overflow-x: hidden;
    width: auto !important;
  }

  .type_of_service {
    font-size: 27px;
    line-height: 40px;
    padding-top: 36px;
    padding-left: 17px;
    text-align: left;
    line-height: 108.5%;
  }

  .content_information--slide {
    overflow-x: hidden;
    margin: 0 auto;
    width: auto !important;
    height: 270px;
    overflow-y: hidden;
    width: 100%;
  }

  .white--block {
    display: none;
  }

  .arrow_next_top--slide {
    display: none;
  }

  .project_from_scratch--left {
    width: 100%;
    height: 100%;
    padding-left: 24px;
    border-radius: 0;
  }

  .hand_top_mobile {
    width: 170px;
    height: 240px;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  .text_project_from {
    width: 98%;
    padding-top: 14px;
    padding-right: 17px;
    font-size: 20px;
    line-height: 25px;
    text-align: left;
  }

  .blocks_infotmation--development {
    height: 100%;
  }

  .promotion--button {
    font-size: 18px;
  }

  .flutter_style {
    padding: 3px 25px;
    margin-top: 20px;
    margin-left: 0;
  }

  .button_top--block {
    padding-top: 40px;
    padding-right: 20px;
    width: auto;
    align-items: flex-end;
  }

  .dot_mobile_bottom--block {
    overflow-x: hidden;
    margin: 0 auto;
    padding-top: 12px;
    width: auto !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .dot {
    margin-right: 15px;
  }

  .services--block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    margin: 0 auto;
    padding-top: 35px;
    width: auto !important;
  }

  .sirvice_block--top {
    display: flex !important;
    flex-direction: row;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 29px;
  }

  .service--text {
    padding-top: 0;
    font-size: 32px;
    padding-top: 32px;
    text-align: center;
  }

  .open_menu--button {
    background: #FFFFFF;
    border-radius: 100px;
    width: 52px;
    height: 26px;
    border: none;
    position: relative;
    transition: transform 0.2s;
  }

  .arrow_transform {
    transition: transform 0.2s;
  }

  .rotated {
    transform: rotate(180deg);
  }

  .type_of_service--blocks {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .blockchaine--block {
    width: 100%;
    justify-content: flex-start;
    height: 69px;
  }

  .blockchaine--text {
    text-align: left;
  }

  .blue_block {
    bottom: 0;
    left: 65%;
  }

  .linux--block {
    width: 100%;
    margin-top: 10px;
    height: 69px;
    border: none;
  }

  .linux--text {
    font-size: 24px;
  }

  .telegram--block {
    height: 69px;
    margin-top: 10px;
  }

  .telegram--title {
    font-size: 24px;
    padding-left: 20px;
  }

  .telegram_airplane {
    right: 0;
    top: 23%;
    bottom: 0;
  }

  .grey_style--block {
    margin-top: 10px;
    background: linear-gradient(90deg, #545454 0%, rgba(97, 97, 97, 0.47) 100%);
  }

  .cases--block {
    overflow-x: hidden;
    margin: 0 auto;
    width: auto !important;
  }

  .computer {
    position: absolute;
    right: 0;
    bottom: 3%;
  }

  .king_durian--block {
    padding: 32px 22px;
    border-radius: 15px;
    width: auto;
  }

  .service_case--text {
    margin-top: 33px;
  }

  .kind_usdt {
    display: none;
  }

  .container {
    width: auto;
    margin: 0 20px;
  }

  .cases_bottom--block {
    flex-direction: column;
  }

  .heat_map--block {
    width: 100%;
    border-radius: 15px;
  }

  .heat_map--title {
    font-size: 22px;
    padding-bottom: 0;
    text-align: left;
    z-index: 3;
    line-height: 27px;
  }

  .heat_map--text {
    z-index: 3;
    width: auto;
    margin-bottom: 0;
    padding-bottom: 150px;
  }

  .mac_studio {
    width: 235px;
    right: 25px;
    bottom: 15px;
  }

  .event_search--block {
    width: 100%;
    border-radius: 15px;
    margin-top: 26px;
  }

  .event_searh--title {
    margin-top: -20px;
    font-size: 22px;
    padding-bottom: 0;
  }

  .event_searh--text {
    padding-bottom: 20px;
    margin-top: -5px;
  }

  .dream_team--block {
    justify-content: center;
    position: relative;
    flex-direction: column;
  }

  .middle_team--image {
    width: 380px;
    height: 263px;
    z-index: 3;
    margin-bottom: 10px;
  }

  .grey_back--block {
    background-color: #7F7F7F;
    height: 230px;
    position: absolute;
    width: 40px;
    left: 1%;
    top: 15%;
    border: none;
  }

  .grey_back--block--right {
    border: none;
    background-color: #7F7F7F;
    height: 230px;
    position: absolute;
    width: 40px;
    right: 1%;
    top: 15%;
  }

  .team_person--block {
    justify-content: center;
    align-items: center;
  }

  .team_person--title {
    margin: 0;
    font-family: 'Russo One';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    padding-bottom: 22px;
  }

  .surname_style--text {
    padding-left: 9px;
  }

  .kind_of_quality--block {
    /* width: 100%; */
    width: 380px;
    flex-direction: row;
    padding: 0 10px;
  }

  .quality_text--block {
    display: flex;
    flex-direction: column;
    padding-left: 9px;
    align-items: flex-start;
  }

  .quality--title {
    color: #FFFFFF;
  }

  .quality--text {
    text-align: left;
  }

  .contact_h1--text {
    padding-top: 51px;
  }

  .content_information--block {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .contat_white_right--block {
    width: 100%;
    position: relative;
  }

  .name_white_right--title {
    padding-left: 20px;
  }

  .content--input {
    margin: 0;
    border: none;
    margin: 0 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #616161;
    border-bottom: 2px solid #616161 !important;
  }

  .top_margin {
    margin: 0 auto;
    margin-top: 21px;
    width: 90%;
  }

  .fio--block {
    display: none;
  }

  .file_upload--block {
    padding: 0 20px;
    padding-top: 47px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--file_upload {
    width: 100%;
    padding-bottom: 17px;
  }

  .btn--block_send-block {
    padding-top: 25px;
  }

  .button_mobile--block {
    padding: 0 20px;
    display: flex !important;
    justify-content: flex-end;
  }

  .grey_open--button {
    background: #D4D4D4;
    margin-top: -20px;
    margin-bottom: 15px;
    transition: transform 0.2s;
  }

  .contact_blue_left--block {
    width: 90%;
    margin-top: -33px;
  }

  .name_block_blue_left--title {
    font-size: 20px;
    padding-top: 41px;
    padding-left: 23px;
  }

  .information_line_contact--block {
    flex-direction: column;
    padding-left: 24px;
  }

  .tube {
    width: 12px;
    height: 12px;
  }

  .envelope {
    width: 13px;
    height: 10px;
  }

  .telegram_blue {
    width: 15px;
    height: 12px;
  }

  .left_information--part {
    align-items: center;
    width: 100%;
  }

  .right_information--part {
    width: 100%;
  }

  .top-right_btn {
    margin-top: 10px;
  }

  .bottom_padding--block {
    padding-bottom: 8px;
  }

  .black_line {
    position: relative;
    width: 60%;
    left: auto;
    z-index: 4;
    margin-top: -20px;
  }

  .adress_width--text {
    width: 80%;
  }

  .pro_apple {
    width: 170px;
    height: 180px;
  }

  .content_mobile_style {
    margin: 0 !important;
  }

  .black_blockchaine--block {
    width: 100%;
    height: 100%;
    border-radius: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .gold_key {
    width: 113px;
    height: 100px;
  }

  .html_style--button {
    padding: 3px 14px;
  }

  .react_style--button {
    padding: 3px 20px;
  }

  .key_development--title {
    width: auto;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .noda--block {
    display: none;
  }

  .web_three--block {
    display: none;
  }

  .black_need--title {
    padding: 23px 21px;
    font-size: 20px;
  }

  .black_need_blue--title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #0B7EE9;
    padding-left: 21px;
    padding-top: 10px;
    width: 50%;
  }

  .flutter_style_mobile_app--button {
    margin-left: 50%;
  }

  .modal-content {
    width: 90%;
  }

  .mobile_app_class--title {
    font-size: 24px;
    padding: 0 16px;
    padding-top: 23px;
    text-align: left;
  }

  .promodal {
    position: relative;
    margin: 17px;
    margin-top: 7px;
    width: 80%;
    margin-left: 17px;
    margin-right: 17px;
  }

  .hr_mobile_app--line {
    justify-content: flex-end;
  }

  .part_information--text {
    padding-left: 19px;
    padding-right: 27px;
    width: auto;
  }

  .bottom_margin--text {
    padding-bottom: 14px;
  }

  .write_to_us--text {
    position: relative;
    padding-left: 38px;
    padding-right: 11px;
    padding-bottom: 7px;
    text-align: left;
  }

  .blue_contact--button {
    position: relative;
    margin-left: 38px;
    margin-bottom: 40px;
  }

  .modal {
    z-index: 10;
  }

  .jadels_modal {
    flex-direction: column;
  }

  .blue_left_part--block {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
  }

  .blue_left_part--title {
    font-size: 24px;
    padding: 10px 15px;
    margin: 30px 19px;
  }

  .blue_left_part--text {
    padding-left: 35px;
    padding-right: 25px;
    padding-bottom: 23px;
  }

  .left_modal_margin--block {
    padding-left: 31px;
  }

  .name_white_right--title {
    text-align: left;
  }

  .mobile_modal_style_content--input {
    width: 85%;
  }

  .wave_blue {
    border-radius: 0px 0px 15px 0px;
  }

  .iphone_pro {
    width: calc(100% + 48px);
    border-radius: 15px 0 0 0;
  }

   /* .prokrutka {
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: hsl(0 0% 50%);
    } */

  .key_development--block {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  .black_blockchaine--title {
    padding-left: 31px;
    width: auto;
  }

  .rigth_footer--block {
    align-items: center;
    width: 100%;
  }

  .contetn_right--text {
    width: 100%;
  }

  .website--style {
    width: 70%;
  }

  .development_key--style {
    width: 70%;
  }

  .not_found--style {
    width: 90%;
  }

  .name--block {
    display: none;
  }

  .number--block {
    display: none;
  }

  .case_name_top {
    padding-top: 33px;
    padding-bottom: 9px;
  }

  .wave_grey {
    display: none;
  }

  .community_style--block {
    flex-direction: column;
    padding-bottom: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px;
    position: relative;
  }

  .type_of_service {
    font-size: 40px;
    padding-left: 0;
    line-height: 45px;
  }

  .website--style {
    width: 60%;
  }

  .content_link_bot--button {
    padding: 11px 33px;
    margin-top: 15px;
  }

  .react_style {
    margin-left: 0;
    padding: 3px 25px;
  }

  .globus {
    left: 0%;
    bottom: 5%;
    width: 40%;
  }

  .types_of_services--block {
    flex-direction: column;
    padding-top: 0;
  }

  .middle_service--block {
    width: 100%;
  }

  .right_service_content--blocks {
    width: 100%;
    margin-top: 20px;
  }

  .sirvice_block--top {
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  .for_an_expert--title {
    font-size: 22px;
    padding-left: 16px;
  }

  .for_an_expert--block {
    width: auto;
    border-radius: 15px 15px 0px 0px;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: 6px;
    padding-bottom: 10px;
  }

  .advantages_exper-title {
    font-size: 22px;
  }

  .advantages_expert--block {
    padding-left: 22px;
  }

  .our_expert--title {
    font-size: 14px;
  }

  .go_to_bot {
    display: block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 0;
    text-align: center;
    background: #0B7EE9;
    border-radius: 10px;
    width: auto;
    padding: 11px;
    margin-top: 20px;
  }

  .go_to_bot:hover {
    background: #191919;
    color: chartreuse;
    transition: 0.3s;
  }

  .sphere_mobile_maps--text {
    font-family: 'Russo One';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0;
    padding: 13px;
  }

  .sphere_mobile_maps--block {
    position: relative;
    background: #0B7EE9;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 53px;
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .sphere_mobile_maps_telegram {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 2%;
  }

  .sphere_mobile_type_of_service {
    margin-top: 0;
  }

  .grey_black_border_style_sphere_mobile_maps--block {
    border: 3px solid #191919;
    background: #D4D4D4;
    margin-top: 13px;
  }

  .color_black_sphere_mobile_maps--text {
    color: #191919;
  }

  .comment_content_people--block {
    min-width: 270px;
    margin: 0 20px;
    margin-top: 50px;
    padding: 30px 20px;
  }

  .love_jedels--right--block {
    width: 100%;
    border-radius: 0;
    align-items: flex-start;
  }

  .love_text_jedels {
    padding-top: 14px;
    padding-left: 22px;
    width: 50%;
    font-size: 22px;
  }

  .logo_sphere_mobile {
    height: 61px;
    margin-left: 32px;
    margin-top: 10px;
  }

  .one_hundred {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 115%;
    display: flex;
    margin: 0;
    color: #FFFFFF;
    margin-left: 18px;
  }

  .users_week {
    margin: 0;
    margin-top: 10px;
    margin-left: 18px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 88.5%;
    display: flex;
    color: #FFFFFF;
    width: 60%;
  }

  .telegram_top--button {
    width: 40px;
    height: 33px;
  }

  .content_link_bot_button--text {
    white-space: nowrap;
  }

  .cripto_case--block {
    width: 100%;
    border-radius: 0;
    height: 100%;
  }

  .capability--title {
    font-size: 22px;
  }

  .capability--text {
    margin-bottom: 30px;
  }

  .slider_wrapper {
    padding-top: 24px;
  }

  .instagram_style_mobile_footer {
    margin-top: 15px;
  }

  .desktop-view_love_jedels {
    display: none;
  }

  .comment_content_people--blocks {
    justify-content: space-between;
  }

  .man_laptop {
    width: 36px;
    height: 39px;
  }

  .yellow_man {
    width: 36px;
    height: 39px;
  }

  .desktop_style_image {
    display: block;
  }

  .desctop_content_information--slide {
    display: none;
  }

  .mobile_style_slider_wrapper {
    display: block;
  }

  .text_part_quality:before {
    margin-right: 10px;
  }

  .desktop_style_iphone_pro {
    display: none;
  }

  .comment_content_people_blue--title {
    font-size: 18px;
  }

  .comment_content_people_expert--title {
    font-size: 16px;
  }

  .text_part_quality {
    font-size: 16px;
  }

  .label_social--title:hover {
    color: red;
    transition: 0.2s;
  }

  .contetn_right--text:hover {
    color: red;
  }

  .content_information_blog--block {
    height: auto;
    flex-direction: column;
    padding-top: 0;
  }

  .content_information_blog--block h3 {
    font-size: 22px;
  }

  .right_blog--block {
    padding: 10px;
  }

  .lamp--block {
    padding: 20px;
  }
  
  .left_content_blog {
    width: auto;
    height: auto;
    margin-bottom: 25px;
  }

  .read {
    position: relative;
    width: 100%;
    text-align: end;
    padding-bottom: 15px;
    font-size: 16px;
  }

  .left_content_blog--text {
    padding: 0 20px;
    padding-top: 5px;
    padding-bottom: 6px;
  }

  .left_content_blog--title {
    font-size: 22px;
    padding: 0 20px;
    padding-top: 15px;
  }

  .right_content_blog--blocks {
    width: auto;
  }

  .lamp--block--title {
    font-size: 30px;
    padding: 39px 12px;
  }

  .desktop_left_content_blog {
    display: none !important;
  }

  .love_padding {
    padding: 39px 6px;
  }

  .right_blog--title {
/*    padding-top: 30px;*/
    font-size: 20px;
  }

  .right_blog-style {
    bottom: 0%;
  }

  .blog_style--text {
    font-weight: 700;
  }

  .community_style--block {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .map_blog {
    width: 100%;
  }

  .love_blog--button {
    width: 30%;
  }

  .absolute_circle {
    left: -2%;
    bottom: -3%;
    right: 88%;
  }

  .data_time--text {
    display: none;
  }

  .descktop_style_left_article {
    display: none;
  }

  .mobile_style_left_article {
    display: block;
    padding-bottom: 55px;
  }

  .absolute_left_article--text {
    left: 5%;
  }

  .right_article--block {
    width: auto;
  }

  .top_right_article--block {
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
  }

  .bottom__right_content_blog {
    margin-bottom: 59px;
  }

  .left_article--block {
    width: auto;
    margin-bottom: 36px;
  }

  .moda-content {
    width: auto;
    margin: 45% auto;
    right: 5%;
  }

  .content_article {
    right: 10%;
    top: 40%;
  }





  .footer--menu {
    padding-bottom: 48px;
    padding-top: 45px;
    flex-direction: column;
  }

  .row_content_mobile {
    flex-direction: column;
  }

  .middle_socail_footer--blocks {
    width: 100%;
    flex-direction: row;
    margin-top: 30px;
  }

  .footer-background--block {
    padding: 10px 15px;
  }

  .social_footer--column {
    width: 100%;
  }

  .rigth_footer--block {
    padding-top: 35px;
  }

  .instagram_footer-block {
    margin-top: 15px;
  }
}


  .wave_grey {
    display: none;
  }

  .color_text_project {
    color: #FFFFFF;
  }

  .white--block {
    background: none !important;
  }
  .project_from_scratch--left {
    background: none !important;
  }

  .cripto_case--block {
    background: none !important;
    border: 1px solid var(--pure-white);
  }
  .dark .cripto_case--block {
    background: none !important;
    border: 1px solid var(--pure-white-theme);
  }
  .capability--text {
    color: var(--pure-white);
  }
  .dark .capability--text {
    color: var(--pure-white-theme);
  }

  .middle_service--block {
    background: #000000 !important;
    border: 1px solid #FFFFFF;
  }
  .middle_service--block * {
    color: #FFFFFF !important;
  }

  .for_an_expert--block {
    background: #FFFFFF !important;
  }
  .for_an_expert--block * {
    color: #191919 !important;
  }

  .buttons_advantages--button {
    background: none;
    border: 2px solid #FFFFFF;
  }
  .dark .buttons_advantages--button {
    border: 2px solid #368CCC;
  }

  .buttons_advantages--button * {
    color: #FFFFFF !important;
  }
  .dark .buttons_advantages--button * {
    color: #368CCC !important;
  }

  .heat_map--block {
    background: none !important;
    border: 1px solid #FFFFFF;
  }
  
  .dark .heat_map--block {
    border: 1px solid #368CCC;
  }

  .black_style--text {
    color: #FFFFFF !important;
  }
  .dark .black_style--text {
    color: #368CCC !important;
  }

  .event_search--block {
    background: none !important;
    border: 1px solid #FFFFFF;
  }

  .dark .event_search--block {
    border: 1px solid #368CCC;
  }

  .maps_telegram--text {
    color: #FFFFFF;
  }

  .comment_content_people--block {
    background: none !important;
    border: 1px solid #FFFFFF;
  }

  .comment_content_people--block {
    border: 1px solid #368CCC;
  }

  .comment_content_people--block * {
    color: #FFFFFF;
  }
  .dark .comment_content_people--block * {
    color: #368CCC;
  }

</style>