<template>
  <div class="slide_sites--block">
    <div class="container content_mobile_style community_style--block row_content_mobile">
      <h1 class="type_of_service website--style">Статистика</h1>
      <p class="stats--description">Россия - Дубай - Бали</p>
    </div>
  </div>

  <div class="container">
    <div class="header_description">
      <p>Будет выглядеть примерно так, когда подтянем реальные данные:</p>
    </div>

    <div class="chart_block">
      <div class="chart">
        <h2>Прирост пользователей</h2>
        <canvas id="newUsersChartId"></canvas>
      </div>
    </div>

    <div class="chart_block">
      <div class="chart">
        <h2>Прирост экспертов</h2>
        <canvas id="expertsChartId"></canvas>
      </div>
    </div>

    <div class="chart_block"> <!-- --row -->
      <div class="chart">
        <h2>Топ 10 Популярных сфер деятельности</h2>
        <canvas id="popularSpheresChartId"></canvas>
      </div>
      <!-- <div class="chart">
        <h2>Конверсия покупок по сферам деятельности</h2>
        <canvas id="conversionClicksBuyId"></canvas>
      </div> -->
    </div>

    <div class="chart_block"> <!-- --row -->
      <!-- <div class="chart">
        <h2>Кол-во покупок PREMIUM подписок</h2>
        <canvas id="premiumBuyChartId"></canvas>
      </div> -->
      <div class="chart">
        <h2>Стартап SPHERE</h2>
        <p class="chart_text">Это <b>НОВАЯ СОЦИАЛЬНАЯ СЕТЬ</b> - Бизнес-Акселератор</p>
        <p class="chart_text"><b>Наша миссия</b> - привлечь на плащадку <b>1.000.000.000 пользователей</b> со всего мира</p>
        <p class="chart_text">Мы даем возможность стать инвестором нашего проекта со стороны пользователей, кто посещает наш сайт и использует его по своим потребностям.</p>
        <p class="chart_text">ПО ИНВЕСТИРОВАНИЮ в развитие напишите <a href="https://sphere-experts.com/expert/1624396003">CEO проекта</a></p>

        <!-- <canvas id="premiumBuyChartId"></canvas> -->
      </div>
    </div>


    <div class="chart_block">
      <div class="chart">
        <h2>Кол-во созданых событий нашими экспертами</h2>
        <canvas id="createdEventsChartId"></canvas>
      </div>
    </div>

    <div class="chart_block">
      <div class="chart">
        <h2>Количество запросов в поисковике Сферы</h2>
        <canvas id="searchRequestsFrequencyChartId"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import * as Utils from '../utils';
import axios from 'axios';

const colorize = (opaque, hover, ctx) => {
  const v = ctx.raw;
  const c = v < 35 ? '#D60000'
    : v < 55 ? '#F46300'
    : v < 75 ? '#0358B6'
    : '#44DE28';

  const opacity = hover ? 1 - Math.abs(v / 150) - 0.2 : 1 - Math.abs(v / 150);
  return opaque ? c : Utils.transparentize(c, opacity);
}

const hoverColorize = (ctx) => colorize(false, true, ctx);

const weekLabels = ['Неделя 1', 'Неделя 2', 'Неделя 3', 'Неделя 4', 'Неделя 5', 'Неделя 6']
const sphereLabels = [
  'Здоровье и саморазвитие',
  'Маркетинг',
  'Финансы и инвестиции',
  'IT-технологии',
  'Творчество и искусство',
  'Фото/Медиа индуcтрия',
  'Управление и менеджмент',
  'Строительство и архитектура',
  'Интернет-торговля/коммерция',
  'Развлечения/досуг'
]
const backgroundColors = [
  Utils.CHART_COLORS.red,
  Utils.CHART_COLORS.blue,
  Utils.CHART_COLORS.green,
  Utils.CHART_COLORS.grey,
  Utils.CHART_COLORS.orange,
  Utils.CHART_COLORS.purple,
  Utils.CHART_COLORS.yellow,
  'rgb(255, 112, 20)',
  'rgb(54, 123, 235)',
  'rgb(255, 15, 86)'
]

export default {
    name: 'Stats',
    data() {
      return {
        newUsers: null,
        newExperts: null, 
        bestSpheres: null,
        newEvents: null,
        newSearchs: null
      }
    },
    components: {
    },
    computed: {},
    mounted() {
      const newUsersChartElement = document.getElementById('newUsersChartId');
      const expertsChartElement = document.getElementById('expertsChartId');
      const popularSpheresChartElement = document.getElementById('popularSpheresChartId');
      const conversionClicksBuyElement = document.getElementById('conversionClicksBuyId');
      const premiumBuyElement = document.getElementById('premiumBuyChartId');
      const createdEventsElement = document.getElementById('createdEventsChartId');
      const searchRequestsFrequencyElement =  document.getElementById('searchRequestsFrequencyChartId');

      axios.get(`${this.$store.state.base_url}/api/statistic/user-growth/`).then(response => {
        if (response.status === 200) {
          this.newUsers = response.data;
          
          const newUsersChart = new Chart(newUsersChartElement, {
            type: 'line',
            data: {
              labels: Object.keys(this.newUsers), //["Январь", "Январь","Январь","Январь","Январь","Январь","Январь","Январь","Январь","Январь","Январь","Январь"], // Object.keys(newUsers),
              datasets: [
                {
                  label: 'Новые пользователи',
                  data: Object.values(this.newUsers),
                  borderColor: Utils.CHART_COLORS.red,
                  backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
                  pointStyle: 'circle',
                  pointRadius: 10,
                  pointHoverRadius: 15
                }
              ]
            },
            options: {
              responsive: true,
              plugins: {
                title: {
                  display: false,
                  text: (ctx) => 'Новые пользователи',
                }
              }
            }
          })
        }
      })
      .catch(error => {
        console.log(error);
      })


      axios.get(`${this.$store.state.base_url}/api/statistic/expert-growth/`).then(response => {
        if (response.status === 200) {
          this.newExperts = response.data;

          const expertsChart = new Chart(expertsChartElement, {
            type: 'line',
            data: {
              labels: Object.keys(this.newExperts),
              datasets: [
                {
                  label: 'Новые эксперты',
                  data: Object.values(this.newExperts),
                  borderColor: Utils.CHART_COLORS.blue,
                  backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
                  pointStyle: 'circle',
                  pointRadius: 10,
                  pointHoverRadius: 15
                }
              ]
            },
            options: {
              responsive: true,
              plugins: {
                title: {
                  display: false,
                  text: (ctx) => 'Point Style: ' + ctx.chart.data.datasets[0].pointStyle,
                }
              }
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
        return null;
      })

      axios.get(`${this.$store.state.base_url}/api/statistic/best-spheres/`).then(response => {
        if (response.status === 200) {
          this.bestSpheres = response.data;

          const popularSpheresChart = new Chart(popularSpheresChartElement, {
            type: 'doughnut',
            data: {
              labels: Object.keys(this.bestSpheres),
              datasets: [{
                label: 'Популярные сферы',
                data: Object.values(this.bestSpheres),
                backgroundColor: backgroundColors,
                hoverOffset: 4
              }]
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
        return null;
      })

      const conversionClicksBuy = null;
      // const conversionClicksBuy = new Chart(conversionClicksBuyElement, {
      //   type: 'polarArea',
      //   data: {
      //     labels: sphereLabels,
      //     datasets: [{
      //       label: '% покупок по сферам',
      //       data: [20, 20, 25, 20, 12, 23, 17, 20, 15, 18],
      //       backgroundColor: backgroundColors
      //     }]
      //   },
      //   options: {
      //     plugins: {
      //       legend: true,
      //       tooltip: true,
      //     },
      //     elements: {
      //       arc: {
      //         backgroundColor: colorize.bind(null, false, false),
      //         hoverBackgroundColor: hoverColorize
      //       }
      //     }
      //   }
      // })

      const premiumBuy = null;
      // const premiumBuy = new Chart(premiumBuyElement, {
      //   type: 'line',
      //   data: {
      //     labels: weekLabels,
      //     datasets: [
      //       {
      //         label: 'Покупки premium',
      //         data: [1, 2, 10, 3, 15, 3],
      //         borderColor: Utils.CHART_COLORS.blue,
      //         backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
      //         stack: 'combined',
      //         type: 'bar'
      //       },
      //       {
      //         label: 'Новые пользователи',
      //         data: [27, 35, 30, 37, 35, 38],
      //         borderColor: Utils.CHART_COLORS.red,
      //         backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
      //         stack: 'combined'
      //       }
      //     ]
      //   },
      //   options: {
      //     plugins: {
      //       title: {
      //         display: true,
      //       }
      //     },
      //     scales: {
      //       y: {
      //         stacked: true
      //       }
      //     }
      //   },
      // })


      axios.get(`${this.$store.state.base_url}/api/statistic/event-growth/`).then(response => {
        if (response.status === 200) {
          this.newEvents = response.data;

          const createdEvents = new Chart(createdEventsElement, {
            type: 'bar',
            data: {
              labels: Object.keys(this.newEvents),
              datasets: [{
                label: '# Новые события',
                data: Object.values(this.newEvents),
                borderWidth: 1,
                backgroundColor: [
                  Utils.CHART_COLORS.blue
                ],
              }]
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }
          });
        }
      })
      .catch(error => {
        console.log(error)
        const createdEvents = null;
      })


      axios.get(`${this.$store.state.base_url}/api/statistic/search-growth/`).then(response => {
        if (response.status === 200) {
          this.newSearchs = response.data;
          console.log(this.newSearchs)
          
          const searchRequestsFrequency = new Chart(searchRequestsFrequencyElement, {
            type: 'bar',
            data: {
              labels: Object.keys(this.newSearchs),
              datasets: [{
                label: '# Частота запросов',
                data: Object.values(this.newSearchs),
                borderWidth: 1,
                backgroundColor: [
                  Utils.CHART_COLORS.yellow
                ],
              }]
            },
            options: {
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }
          });
        }
      })
      .catch(error => {
        console.log(error)
        return null;
      })

      premiumBuy;
      createdEvents;
      searchRequestsFrequency;
      newUsersChart;
      expertsChart;
      popularSpheresChart;
      conversionClicksBuy;
    }
}
</script>

<style scoped>
  .stats--description {
    font-size: 24px;
  }
  
  .header_description {
    font-size: 1.3em;
    margin-bottom: 50px;
  }

  .chart_block {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
  }

  .chart_block--row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .chart_block--row .chart {
    width: 48%;
  }

  .chart_block .chart {
    width: 100%;
  }

  .chart > h2 {
    margin-bottom: 34px;
  }

  .chart_text {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 700px) {
    .chart_block--row {
      flex-direction: column;
    }
    .chart_block--row .chart {
      width: 100%;
    }
  }

</style>