<template>
    <section class="bg-black-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-white">
        SPHERE-EXPERTS.COM
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl dark:text-white">
                  TEAM ACCESS
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="nickname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Login</label>
                      <input type="text" name="nickname" id="nickname" v-model="nickname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="@nickname" required="">
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" name="password" id="password" v-model="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                  </div>
                  <button type="submit" @click="doLogin" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
              </form>
          </div>
      </div>
  </div>
</section>
</template>

<script>
import { DEV_ACCESS_COOKIE_NAME } from '../constants';
import teamData from '../../team-data.json'
import { setCookie } from '../utils';
import bcrypt from 'bcryptjs';

export default {
    name: 'Login',
    data () {
        return {
            nickname: "",
            password: "",
            emptyFields: false
        }
    },
    methods: {
      async doLogin() {
         if (this.nickname === "" || this.password === "") {
            this.emptyFields = true;
         } else {
            const user = teamData.team.filter(user => user.nickname === this.nickname);
            if (user.length && process.env.VUE_APP_DEV_PASS === this.password) {
               const salt = await bcrypt.genSalt(10);
               bcrypt.hash(this.password, salt, (err, hash) => {
                  if (err) {
                     console.log('encryptValue -> error: ', err)
                     return;
                  }
                  console.log('success! setup auth user passHash: ', hash);
                  setCookie(DEV_ACCESS_COOKIE_NAME, hash, 7);
                  alert("Вы успешно зарегистрированны!");
               });
            } else {
               alert("Неверный логин или пароль");
            }
         }
      }
    }
}

</script>