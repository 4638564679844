<template>
  <div class="slide_sites--block">
    <div class="container content_mobile_style community_style--block style_content_mobile_blog">
      <a class="type_of_service blog_style_type_of_service" href="">SPHERE</a>
        <img class="charter_laptop_man" src="/images/arrow_blog.svg" alt="">
      <a class="type_of_service website--style blog_style_type_of_service" href="">Статья</a>
    </div>
  </div>

  <div class="services--block">
    <div class="container">
      <div class="article--block">
        <div class="left_article--block" id="articleContent">
          <!-- Ответ API -->
          {{articleId}}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapMutations,mapActions, mapState } from "vuex"; 

export default {
  name: 'Article',
  components: {
  },
  data() {
    return {
      article: false
    }
  },
  computed: {
      articleId () {
        return +this.$route.params.id;
      }
  },
  created() {
    console.log(this.articleId)
    let data = {
      'id': this.articleId
    }
    this.getArticle(data);
  },
  methods: {
    getArticle() {
      axios.get(`${this.$store.state.base_url}/api/blog/get/`, {
        params: {
          articleId: this.articleId
        }
      })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          this.article = response.data
        }
      })
      .catch(error => {
        console.log(error)
      })

      if (!this.article) {
        this.$router.push('/')
      }
    }
  },
}
</script>