<template>
  <!-- <div class="slide_sites--block">
    <div class="container content_mobile_style community_style--block style_content_mobile_blog"> <a class="type_of_service blog_style_type_of_service" href="">SPHERE</a> <img class="charter_laptop_man" src="@/assets/images/arrow_blog.svg" alt=""> <a class="type_of_service website--style blog_style_type_of_service" href="">Эксперт</a> </div>
  </div> -->
  <div class="services--block">
    <div class="container">
      <div class="content_mobile_style">
        <h1 class="type_of_service">Профиль Эксперта</h1>
      </div>
      <!-- <img class="dasha_mobile" src="@/assets/images/dasha.svg" alt=""> -->
      <div class="top_content_people_experts">
        <div class="expert-photo--block--mobile">
          <img v-if="expert.profile_photo" :src="$store.state.base_url + expert.profile_photo">
          <img v-else src="@/assets/images/globus.svg">
        </div>
        <div class="left_top_content_people_experts">
          <p class="left_top_content_people_experts--title">{{ expert.fullname }}&#x200b;</p>
        </div>
        <div class="right_top_content_people_experts">
          <img class="experts_love_blog" src="@/assets/images/love_blog.svg" alt="">
          <p class="data_right_people_experts--text">Дней в SPHERE: {{ expert.days }}</p>
        </div>
        <div class="right_top_content_people_experts">
          <p class="data_right_people_experts--text no-padding">💰&ensp;Баланс: {{ expert.balance }}</p>
        </div>
      </div>


      <div class="experts--blocks">
        <div class="child-expert--block left_experts--block">
          <div class="middle_comment_content" v-html="expert.spheres_text"></div>
          <!-- <img class="spout" src="@/assets/images/spout.png" alt=""> -->
        </div>
        <div class="child-expert--block right_experts--block">
          <div class="top-child-expert--block">
            <div class="social_right_experts--blocks">
              <a class="social_right_experts" :href="link.url" target="_blank" v-for="link in expert.links">
                <div class="left_social_right_experts">
                  <!-- <img class="inst_experts" src="@/assets/images/inst_experts.svg" alt=""> -->
                  <img class="inst_experts" src="@/assets/images/planet.svg" alt="">
                  <a class="left_social_right_experts-text" :href="link.url" target="_blank">{{ link.name }}</a>
                </div>
              </a>
            </div>
            <div class="expert-photo--block desktop-view">
              <img v-if="expert.profile_photo" :src="$store.state.base_url + expert.profile_photo">
              <img v-else src="@/assets/images/globus.svg">
            </div>
          </div>
          <div class="bottom_social_right_experts">
            <div> 
              <!-- <p class="number_of_pregs">Приглашенных: {{ expert.referals_count }}</p> -->
              <p class="number_of_pregs">Приглашенных:<br>Пользователей - {{expert.referals_count}}<br>Экспертов - {{expert.referals_experts_count}}</p>
            </div>
            <div class="relative">
              <div class="flex flex-col items-end w-full p-4 absolute bottom-full">
                <p class="text-xl mb-1">Просмотров: {{ expert.page_views_all }}</p>
                <p class="text-sm relative"><span class="eye_text_icon absolute text-xl right-full mr-2">👁</span> cегодня {{ expert.page_views_today ? `+ ${expert.page_views_today}` : 0 }}</p>
              </div>
              <a :href="this.$store.state.expertsBotUrl + '?start=_' + expert.id" target="_blank" class="right_bottom_social_right_experts">
                <img class="airplane_experts" src="@/assets/images/airplane_experts.svg" alt="">
                <a class="right_number_of_pregs">Ссылка Эксперта</a>
              </a>
            </div>
          </div>
          <!-- <img class="dasha" src="@/assets/images/dasha.svg" alt=""> -->
        </div>
      </div>
    </div>
    
    <div class="container">
      <h1 class="service--text" id="blog">О себе</h1>
      <div class="about--container">
        <div class="about--block" v-if="expert.account_card">
          <div class="about_me--block">
            <p class="about_me--title" v-html="expert.account_card.text"></p>
            <!-- <img class="mobile_airplane_blue" src="@/assets/images/mobile_airplane_blue.svg" alt=""> -->
          </div>
          <!-- 
            <img class="about_me_experts" src="@/assets/images/about_me_experts.svg" alt="">
            <img class="left_airplane_blue" src="@/assets/images/left_airplane_blue.svg" alt="">
            <img class="right_airplane_blue" src="@/assets/images/right_airplane_blue.svg" alt="">
          -->
        </div>
        <div class="about--photo">
          <img class="" src="@/assets/images/globus.svg">
          <p class="left_top_content_people_experts--text" v-html="expert.account_connect"></p>
          <img class="qr--photo" v-if="expert.qr_photo" :src="$store.state.base_url + expert.qr_photo" />
        </div>
      </div>
    </div>
    

    <div class="publication_experts">
      <div class="container">
        <h1 class="service--text experts_service--text publicationservice--text" id="blog">Публикации</h1>
        <div class="publication_experts--blocks">
          

          <div class="publication_experts--block top_publication_experts" v-for="publication in expert.publications">
            <img class="publication_photo" :src="$store.state.base_url + publication.photo" :alt="publication.event_name">
            <div class="right_publication_experts">
              <div class="top_right_publication_experts top_publication_experts_content">
                <p class="top_right_publication_experts_left">{{ publication.event_name }}</p>
                <div class="right_top_right_publication_experts" v-if="publication.actual == true">
                  <img class="star_gold" src="@/assets/images/star_gold.svg" alt="Gold Star">
                  <p class="right_top_right_publication_experts--text" v-if="actual">Актуально</p>
                </div>
              </div>
              <p class="right_publication_experts--text desctop_right_publication" v-html="publication.description"></p>
              <p class="right_publication_experts--text mobile_right_publication" v-html="publication.description"></p>
              <!-- <div class="bottom_right_publication_experts">
                <p class="left_bottom_right_publication_experts desctop_right_publication">Дата публикации: 12.03.23</p>
                <p class="left_bottom_right_publication_experts mobile_right_publication">12.03.23</p>
                <p class="right_bottom_right_publication_experts">Посмотреть публикацию</p>
              </div> -->
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>


<script>
import axios from 'axios';
import { expertView } from '../utils'
import { viewSource } from '../constants'

export default {
  name: 'ExpertView',
  data() {
    return {
      expert: false
    }
  },
  components: {},
  computed: {
    expertId() {
      return +this.$route.params.id;
    },
    viewerId() {
      return this.$store.state.user ? this.$store.state.user.id : null;
    }
  },
  async created() {
    await this.getExpert();
    expertView(
      this.$store.state.base_url,
      this.expertId,
      this.viewerId,
      viewSource.EXPERT_PAGE_VIEW
    );
  },
  methods: {
    async getExpert() {
      await axios.get(`${this.$store.state.base_url}/api/expert/get/`, {
        params: {
          expertId: this.expertId
        }
      })
      .then(response => {
        console.log(response);
        if (response.status === 200) {
          console.log(response.data);
          this.expert = response.data
        }
      })
      .catch(error => {
        console.log(error)
      })

      if (!this.expert) {
        // this.$router.push('/')
      }
    }
  },
}
</script>



<style scoped>

.eye_text_icon {
  bottom: -5px;
}

.type_of_service {
  padding-top: 50px;
}

.love_blog {
  width: 18px;
  height: 20px;
}

.time_blog {
  width: 30px;
  height: 33px;
  margin: 7px 12px 8px 12px;
}

.lamp_blog_article {
  width: 30px;
  height: 33px;
  margin: 39px 11px;
}

.top_left_article {
  padding-top: 29px;
  padding-left: 42px;
}

.love_blog_lamp {
  width: 18px;
  height: 20px;
  margin-left: 20px;
}

.top_content_people_experts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.left_top_content_people_experts {
  display: flex;
  flex-direction: column;
}

.left_top_content_people_experts--title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 39px;
  line-height: 49px;
  color: #0B7EE9;
  margin: 0;
}

.left_top_content_people_experts--text {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: #616161;
}

.left_top_content_people_experts--text * {
  color: #616161;
}

.right_number_of_pregs {
  color: #FFFFFF;
}

.experts_community_style {
  padding-bottom: 5px;
}

.right_top_content_people_experts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 129px;
  padding: 10px 25px;
}

.about_me--title {
  color: #0B7EE9;
  font-size: 24px;
  line-height: 30px;
}

.experts_love_blog {
  width: 21px;
  height: 23px;
}

.data_right_people_experts--text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 108.5%;
  color: #000000;
  margin: 0;
  padding-left: 13px;
}

.data_right_people_experts--text.no-padding {
  padding-left: 0;
}

.experts--blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 90px 0 50px;
}

.child-expert--block {
  padding: 20px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
}

.left_experts--block {
  background: #FFFFFF;
  width: 35%;
}

.comment_content_people_experts--title{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 108.5%;
  color: #191919;
  margin: 0;
  padding-left: 10px;;
}

.middle_comment_content {
  z-index: 2;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 108.5%;
  color: #191919;
  margin: 0;
  z-index: 2;
}


.bottom_middle_comment {
  padding-bottom: 13px;
}

.top_middle_comment {
  padding-top: 6px;
}

.right_experts--block {
  background: #0B7EE9;
  width:  63%;
  flex-direction: column;
  justify-content: space-between;
}

.social_right_experts {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  width: 65%;
}

.top-child-expert--block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.expert-photo--block--mobile {
  display: none;
}

.expert-photo--block {
  display: flex;
  transform: translate3d(50px, calc(-50%), 0px);
  background: #000000;
  padding: 14px;
  border-radius: 50%;
}

.expert-photo--block img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}

.social_right_experts--blocks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.left_social_right_experts {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.inst_experts {
  margin-right: 8px;
}

.left_social_right_experts-text {
  width: 100%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
  margin: 0;
}

.top_left_social {
  padding-top: 26px;
}

.width_left_social_right {
  width: 50%;
  text-align: left;
}

.bottom_social_right_experts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -20px;
  margin-right: -20px;
}

.number_of_pregs {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
}

.right_bottom_social_right_experts {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 15px;
  background: #3C9FFA;
  border-radius: 27px 0px;
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #FFFFFF;
  padding-left: 19px;
  cursor: pointer;
  z-index: 20;
}

.dasha {
  position: absolute;
  top: 2%;
  right: -1%;
  border:15px solid #D4D4D4;
  border-radius: 50%;
}

.position_slide_sites {
  position: relative;
}

.experts_service--text {
  padding-top: 0px;
}

.spout {
  position: absolute;
  width: 74px;
  background: white;
  border-radius: 6px;
  bottom: -6%;
  transform: rotate(62deg);
  left: 15%;
}

.about--container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.about--block {
  width: 100%;
  position: relative;
  padding-top: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 25px;
}

.about--photo {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.about--photo > .qr--photo {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.about_me--block {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  z-index: 5 !important;
}

.about_me--block * {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #FFFFFF;
}

.color_black_about_me {
  color: #000000;
  padding-top: 25px;
  font-weight: 600;
}

.about_me_experts {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
}

.index_content {
  z-index: 2;
}

.left_airplane_blue {
  position: absolute;
  bottom: 10%;
  left: 1%;
  width: 80px;
}

.right_airplane_blue {
  position: absolute;
  bottom: 15%;
  right: 5%;
}

.publication_experts {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.publicationservice--text {
  padding-top: 39px;
}

.publication_experts--blocks {
  display: flex;
  flex-direction: column;
  padding-top: 7px;
}

.publication_experts--block {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  border-radius: 20px;
}

.right_publication_experts {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.top_right_publication_experts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.top_right_publication_experts_left {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 108.5%;
  color: #000000;
}

.right_top_right_publication_experts {
  background: #191919;
  border-radius: 129px;
  padding-left: 25px;
  padding-right: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.star_gold {
  width: 21px;
  height: 23px;
  margin-top: 6px;
  margin-bottom: 4px;
  margin-right: 13px;
}

.right_top_right_publication_experts--text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 108.5%;
  color: #FFFFFF;
  margin: 0;
}

.right_publication_experts--text {
  padding-top: 15px;
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 134.2%;
  color: #000000;
}

.bottom_right_publication_experts {
  padding-top: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left_bottom_right_publication_experts {
  margin: 0;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 108.5%;
  color: #616161;
}

.right_bottom_right_publication_experts {
  margin: 0;
  padding: 5px 23px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #FFFFFF;
  background: #0B7EE9;
  border-radius: 20px;
}

.top_publication_experts {
  margin: 0;
  margin-top: 24px;
  margin-bottom: 83px;
}

.publication_photo {
/*  width: 40%;*/
  max-height: 250px;
  border-radius: 20px 0px 0px 20px;
}

.dasha_mobile {
  display: none;
}

.mobile_experts_girls {
  display: none;
}

.mobile_right_publication {
  display: none;
}

.mobile_airplane_blue {
  display: none;
}




@media (max-width: 701px) {

  .services--block {
    width: 100% !important;
  }

  .left_top_content_people_experts {
    text-align: center;
    margin: 0 auto;
  }

  .right_top_content_people_experts {
    margin: 0 auto;
  }

  .left_top_content_people_experts--title {
    font-size: 24px;
  }

  .data_right_people_experts--text {
    font-size: 20px;
  }

  .type_of_service {
    font-size: 36px;
    text-align: center;
  }

  .top_content_people_experts {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .right_bottom_social_right_experts {
    padding: 10px;
  }

  .number_of_pregs {
    font-size: 16px;
  }

  .right_number_of_pregs {
    font-size: 18px;
  }

  .experts--blocks {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .child-expert--block {
    width: 100%;
    padding: 10px;
  }

  .bottom_social_right_experts {
    margin-top: 20px;
    margin-bottom: -10px;
    margin-right: -10px;
  }

  .about_me--title {
    font-size: 20px;
    line-height: 24px;
  }


  .publication_experts--block {
    flex-direction: column;
  }

  .publication_photo {
    width: 100%;
    max-height: fit-content;
    border-radius: 20px 20px;
  }

  .expert-photo--block--mobile {
    display: flex;
    margin: 0 auto;
    padding: 14px;
    border-radius: 50%;
  }

  .expert-photo--block--mobile img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  .top_content_people_experts {
    gap: 0;
  }

  .right_top_content_people_experts {
    margin-top: 20px;
  }

  .about--container {
    flex-direction: column-reverse;
  }

  .about--photo {
    width: 80%;
  }

  .qr--photo {

  }



}

</style>

  


<style scoped>
  
.left_experts--block {
  background: #000000;
  border: 1px solid #FFFFFF;
}

.left_experts--block * {
  color: #FFFFFF;
}

.about--block {
  background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
}

.about_me--block {
  background: none;
}

</style>