<template>
  <div class="slide_sites--block">
    <div class="container content_mobile_style community_style--block row_content_mobile">
      <h1 class="type_of_service website--style">SPHERE</h1>
      <a class="content_link_bot--button" href="/find">
        <img class="telegram_top--button" src="@/assets/images/telegram_top--button.svg" alt="">
        <h2 class="content_link_bot_button--text">Поиск экспертов</h2>
      </a>
    </div>
  </div>

  <div class="content_information--slide desctop_content_information--slide">
    <div class="white--block"></div>
    <div class="blocks_infotmation--development container content_mobile_style">        
      <div class="project_from_scratch--left">
        <!-- <img class="hand_top desktop-view" src="@/assets/images/hand_top.svg?v=2" alt=""> -->
        <img class="globus mobile-view" src="@/assets/images/globus_mobile.svg" alt="">
        <h2 class="text_project_from"><span class="color_text_project">Найди любого эксперта и его событие в 3 клика</span> - за 15 секунд!</h2>
        <div class="button_top--block">
          <a href="/find"><button class="promotion--button">По&nbsp;экспертности</button></a>
          <a href="/stats"><button class="promotion--button flutter_style">По&nbsp;статистике</button></a>
          <a href="/messenger"><button class="promotion--button react_style">По&nbsp;событию</button></a>
        </div>
      </div>
      <div class="cripto_case--block desktop-view_love_jedels">
        <p class="capability--title">Больше возможностей совсем скоро!</p>
        <h2 class="capability--text">Мы продолжаем работать над проектом, чтобы вам было приятно пользоваться сервисом и вы быстро могли найти эксперта или подходящий ивент</h2>
        <h3 class="capability--text top_style_capability--text">Ежедневно десятки новых участников добавляются по QR-code или ссылке-приглашению</h3>
      </div>
      <div class="love_jedels--right--block desktop-view_love_jedels">
        <h2 class="love_text_jedels" style="text-align: center;">Регистрации нет!<br>⭐️ Вход в 1 клик ⭐️</h2>
        <img class="globus" src="@/assets/images/globus.svg" alt="">
      </div>
    </div>
  </div>
  <div class="slider_wrapper mobile_style_slider_wrapper" id="my_slider">
    <div class="slider_items">
      <div class="slider_item">
        <div class="content_information--slide">
          <div class="white--block"></div>
          <div class="blocks_infotmation--development container content_mobile_style">        
            <div class="project_from_scratch--left">
              <img class="hand_top desktop-view" src="@/assets/images/hand_top.svg" alt="">
              <img class="globus mobile-view" src="@/assets/images/globus_mobile.svg" alt="sphere experts">
              <h3 class="text_project_from"><span class="color_text_project">Найди любого эксперта и его событие в 3 клика</span> – за 15 секунд!</h3>
              <div class="button_top--block">
                <a href="/find"><button class="promotion--button">По экспертности</button></a>
                <a href="/stats"><button class="promotion--button flutter_style">По статистике</button></a>
                <a href="/messenger"><button class="promotion--button react_style">По событию</button></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slider_item mobile_view_style">
        <div class="content_information--slide mobile-view">
          <div class="love_jedels--right--block">
            <h2 class="love_text_jedels">Регистрации нет!<br>⭐️ Вход в 1 клик ⭐️</h2>
            <img class="logo_sphere_mobile" src="@/assets/images/logo_sphere_mobile.svg" alt="">
            <h3 class="users_week">Ежедневно десятки новых участников!</h3>
            <img class="hand_top_mobile" src="@/assets/images/hand_top.svg" alt="">
          </div>
        </div>
      </div>
      <div class="slider_item mobile_view_style">
        <div class="content_information--slide mobile-view">
          <div class="cripto_case--block">
            <h4 class="capability--title">Больше возможностей совсем скоро!</h4>
            <h3 class="capability--text">Мы продолжаем работать над проектом, чтобы вам было приятно пользоваться сервисом и вы быстро могли найти эксперта или подходящий эвент</h3>
          </div>
        </div>
      </div>
    </div>  
    <div class="navigation_block">
      <button class="key_button" id="reviewsPreview"></button>
      <button class="key_button" id="reviewsNext"></button>
    </div>      
  </div>

  <div class="services--block">
    <div class="sirvice_block--top container content_mobile_style">
      <h2 class="service--text" id="service">Инструкция</h2>
    </div>
    <div class="container" style="padding-bottom: 40px;">
      <div class="types_of_services--block">
        <div class="right_service_content--blocks">
          <div class="right_service--child">
            <div class="for_an_expert--block white_for_an_expert">
              <img class="yellow_man" src="@/assets/images/yellow_man.svg" alt="">
              <p class="for_an_expert--title black_for_an_expert--title">Для пользователя</p>             
            </div>
            <div class="advantages_expert--block">
              <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
              <p class="advantages_exper-title white_advantages_exper-title">Найди <span class="black_advantages_bold-text">полезные контакты</span> или <span class="black_advantages_bold-text">эксперта</span> всего за 10 секунд по кнопке "Найти эксперта"</p>
            </div>
            <div class="advantages_expert--block">
              <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
              <p class="advantages_exper-title white_advantages_exper-title">Нажми <span class="black_advantages_bold-text">визитку</span> и ознакомься с экспертом</p>
            </div>
            <div class="advantages_expert--block bottom_padding_expert--block">
              <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
              <p class="advantages_exper-title white_advantages_exper-title">Изучи <span class="black_advantages_bold-text">публикации</span> эксперта, а понравившиеся добавляй в<br>"⭐️ Актуальное ⭐️", нажав "Интересно"</p>
            </div>            
          </div>
          <div class="buttons_advantages_expert--block">
            <div class="buttons_advantages_expert--child">
              <a class="buttons_advantages--button" :href="this.$store.state.expertsBotUrl">
                <img class="buttons_advantages--emoji" src="@/assets/images/man_laptop.svg" alt="">
                <h3 class="buttons_advantages--title">Найти эксперта</h3>
              </a>
              <a class="buttons_advantages--button" :href="this.$store.state.expertsBotUrl">
                <img class="buttons_advantages--emoji" src="@/assets/images/blue_people.svg" alt="">
                <h3 class="buttons_advantages--title">Профиль</h3>
              </a>
            </div>
            <div class="buttons_advantages_expert--child">
              <a class="buttons_advantages--button" :href="this.$store.state.expertsBotUrl">
                <!-- <img class="buttons_advantages--emoji" src="@/assets/images/newspaper.svg" alt=""> -->
                <h3 class="buttons_advantages--title">⭐️ Актуальное ⭐️</h3>
              </a>
            </div>
          </div>  
        </div>
        <div class="middle_service--block">
          <div class="for_an_expert--block">
            <img class="man_laptop" src="@/assets/images/man_laptop.svg" alt="">
            <h3 class="for_an_expert--title">Для эксперта</h3>              
          </div>
          <div class="advantages_expert--block">
            <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
            <p class="advantages_exper-title">Нажми на "Профиль" и выбери "Визитка"<br>Заполни фото и описание</p>
          </div>
          <div class="advantages_expert--block">
            <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
            <p class="advantages_exper-title">Выберите свои <span class="black_advantages_bold-text">экспертные области</span> и редактируйте их</p>
          </div>
          <div class="advantages_expert--block">
            <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
            <p class="advantages_exper-title">Собираетесь рассказать о своем <span class="black_advantages_bold-text">проекте?</span> Добавьте его в свой профиль и получайте заявки от пользователей</p>
          </div>
          <div class="advantages_expert--block bottom_padding_advantages_expert--text">
            <img class="arrow_blue-image" src="@/assets/images/arrow_blue.svg" alt="">
            <p class="advantages_exper-title">Хотите получить опыт и выслушать других экспертов? Тогда ознакомьтесь с актульными публикациями других экспертов нашего сообщества и добавьте их в <span class="black_advantages_bold-text">избранное</span> событий</p>
          </div>
        </div>
      </div>
      <h2 class="service--text case_name_top">Возможности</h2>
      <div class="cases--block">
        <div class="cases_bottom--block">
          <div class="heat_map--block">
            <h3 class="heat_map--title">Подключи свои сообщества <span class="black_style--text">по пригласительной ссылке</span></h3>
            <h4 class="heat_map--text">При каждой оплате услуги 3 вышестоящих пригласителя получают по <span class="bold_style--text">10 %</span> от суммы платежа</h4>
            <!-- <button class="detail--button desktop-view">Подробнее</button> -->
            <img class="wave_blue mobile-view mobile_block" src="@/assets/images/mobile_black_wave.svg" alt="">
            <img class="wave_blue desktop-view" src="@/assets/images/wave_blue.svg" alt="">
            <img class="mac_studio" src="@/assets/images/mac_studio.svg" alt="">
          </div>
          <div class="event_search--block">
            <img class="iphone_pro desktop_style_iphone_pro" src="@/assets/images/iphone_pro.svg" alt="">
            <img class="iphone_pro desktop_style_image" src="@/assets/images/iphone_pro_mobile.svg" alt="">
            <h3 class="event_searh--title">SPHERE</h3>
            <h4 class="event_searh--text">Смотрите общую и персональную статистику сервиса. Также получайте оповещения о заявках клиентов и партнёрских начислениях за приглашенных пользователей</h4>
            <div class="content_button_search--block desktop-view">
              <a class="detail--button search--btn" :href="this.$store.state.expertsBotUrl">Перейти в бот</a>
            </div>
          </div>
          <a class="go_to_bot" :href="this.$store.state.expertsBotUrl">Перейти в бот</a>
        </div>
      </div>
      <h2 class="service--text" id="cases">SPHERE Road-Map</h2>
      <div class="type_of_service--blocks mobile-view mobile_block sphere_mobile_type_of_service">
        <a class="sphere_mobile_maps--block" :href="this.$store.state.expertsBotUrl">
          <h3 class="sphere_mobile_maps--text">✅ Telegram Bot</h3>
          <img class="sphere_mobile_maps_telegram" src="@/assets/images/sphere_mobile_maps_telegram.svg" alt="">
        </a>
        <div class="sphere_mobile_maps--block grey_black_border_style_sphere_mobile_maps--block">
          <h3 class="sphere_mobile_maps--text color_black_sphere_mobile_maps--text">✅ Payment BETA</h3>
          <img class="sphere_mobile_maps_telegram" src="@/assets/images/sphere_mobile_maps_telegram.svg" alt="">
        </div>
        <div class="sphere_mobile_maps--block grey_black_border_style_sphere_mobile_maps--block">
          <h3 class="sphere_mobile_maps--text color_black_sphere_mobile_maps--text">✅ Web-App</h3>
          <img class="sphere_mobile_maps_telegram" src="@/assets/images/sphere_mobile_maps_telegram.svg" alt="">
        </div>
        <div class="sphere_mobile_maps--block grey_black_border_style_sphere_mobile_maps--block">
          <h3 class="sphere_mobile_maps--text color_black_sphere_mobile_maps--text">🔜 Mobile App</h3>
          <img class="sphere_mobile_maps_telegram" src="@/assets/images/gear_white.svg" alt="">
        </div>
        <div class="sphere_mobile_maps--block grey_black_border_style_sphere_mobile_maps--block">
          <h3 class="sphere_mobile_maps--text color_black_sphere_mobile_maps--text">🔜 DAO</h3>
          <img class="sphere_mobile_maps_telegram" src="@/assets/images/gear_white.svg" alt="">
        </div>
      </div>
      <div class="maps_sphere--block desktop-view">
        <a class="part_maps_sphere--block" :href="this.$store.state.expertsBotUrl">
          <img class="" src="@/assets/images/maps_telegram.svg" alt="">
          <h3 class="maps_telegram--text">✅ Telegram Bot</h3>
        </a>
        <div class="part_maps_sphere--block">
          <img class="" src="@/assets/images/maps_telegram.svg" alt="">
          <h3 class="maps_telegram--text">✅ Payment BETA</h3>
        </div>
        <div class="part_maps_sphere--block">
          <img class="" src="@/assets/images/maps_telegram.svg" alt="">
          <h3 class="maps_telegram--text">✅ Web-App</h3>
        </div>
        <div class="part_maps_sphere--block">
          <img class="maps_telegram--second" src="@/assets/images/loading_map.svg" alt="">
          <h3 class="maps_telegram--text">🔜 Mobile Apps</h3>
        </div>
        <div class="part_maps_sphere--block">
          <img class="maps_telegram--third" src="@/assets/images/loading_map.svg" alt="">
          <h3 class="maps_telegram--text">🔜 DAO</h3>
        </div>
        <img class="black_maps_line" src="@/assets/images/black_maps_line.svg" alt="">
      </div>
      

      <!-- <h2 class="service--text" id="blog">Блог</h2>
      <div class="content_information_blog--block" v-if="leftArticle && rightArticles">
        <div id="leftContentBlog" class="left_content_blog">
          <div class="article--main_photo--block">
            <img :src="'https://sphere-experts.space' + leftArticle.photo" :alt="leftArticle.photo_alt">
          </div>
          <div class="left_content_blog--content">
            <h3 class="left_content_blog--title">{{leftArticle.name}}</h3>
            <h4 class="left_content_blog--text" v-html="leftArticle.short_description"></h4>
          </div>

        </div>
        <div id="rightContentBlog" class="right_content_blog--blocks">

          <div class="right_content_blog--block" v-for="article in rightArticles">
            <div class="lamp--block"> :style="background: article.article_type.photo_background;"
              <img :src="'https://sphere-experts.space' + article.article_type.photo" class="lamp--block--photo" :alt="article.article_type.name" />
            </div>
            <div class="right_blog--block">
              <h3 class="right_blog--title">{{article.name}}</h3>
              <h4 class="right_blog--text desktop_left_content_blog" v-html="article.short_description"></h4>
              <a :href="'/blog/' + article.id" class="read right_blog-style">Читать</a>
            </div>
          </div>

        </div>
      </div> -->
      <h2 class="team_block--black service--text" id="experts">Лучшая десятка экспертов</h2>
    </div>
    
    <div class="team_block--black">
      <div id="sphereExperts" class="container content_mobile_style comment_content_people--blocks prokrutka" v-if="topExperts">

        <!-- Подстановка данных с бекенда -->
        <div class="top-expert--block" v-for="expert in topExperts">
          <div class="comment_content_people--block">
            <div class="top_comment_content_people--block">
              <img class="charter_laptop_man" src="@/assets/images/man_laptop.svg" alt="Стикер мужчина за компьютером">
              <p class="comment_content_people_blue--title" v-html="expert.account_connect"></p>
            </div>
            <p class="comment_content_people_black--title">👨 {{expert.full_name}}</p>

            <div class="expert-photo--block__find">
              <img v-if="expert.profile_photo" :src="$store.state.base_url + expert.profile_photo">
              <img v-else src="@/assets/images/globus.svg">
            </div>

            <div class="top_comment_content_people--block expert_top_comment_content_people">
              <img class="expert_laptop_man" src="@/assets/images/man_laptop.svg" alt="Стикер мужчина за компьютером">
              <p class="comment_content_people_expert--title">Сферы экспертности:</p>
            </div>
            <p class="part_sphere" v-html="expert.spheres_text"></p>
            <br>
            <p class="part_sphere">Приглашенных:<br>Пользователей - {{expert.referals_count}}<br>Экспертов - {{expert.referals_experts_count}}</p>
          </div>
          <div class="profile--block__buttons">
            <a class="profile--button blue" v-if="expert.have_page" :href="expert.page_url" target="_blank">Страница эксперта</a>
          </div>
        </div>

      </div>
    </div>
  </div>  
</template>




<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: 'Land',
  components: {
  },
  computed:{
    ...mapState(["topExperts"]),
  },
  data() {
    return {
      leftArticle: false,
      rightArticles: [],
    }
  },
  created() {
    this.getExperts();
    // this.getArticles().then(
    //   () => {
    //     console.log('this.blogArticles')
    //     console.log(this.$store.state.blogArticles)
    //     let rights = [];
    //     for (var index in this.$store.state.blogArticles) {
    //       let article =  this.$store.state.blogArticles[index];
    //       if (index == 0) this.leftArticle = article;
    //       else rights.push(article);
    //     }
    //     this.rightArticles = rights
    //   }
    // )
  },
  methods: {
    ...mapActions(["getArticles", "getExperts"]),
  },
}
</script>


<style scoped>
  .expert-photo--block__find {
  }

  .expert-photo--block__find img {
    width: 120px;
    height: 120px;
    margin: 20px auto 0;
    border-radius: 50% 50%;
  }
  
  .top-expert--block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 100px;
  }

  .profile--block__buttons {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto 10px;
    margin-top: 10px;
  }

  .profile--button {
    width: auto;
    padding: 7px;
    border-radius: 10px;
    background: #1D1D24;
    color: #FFFFFF;
    text-align: center;
    outline: 2px solid #1D1D24;
    transition: 0.4s;
  }

  .profile--button:hover {
    transition: 0.4s;
    color: #0B7EE9;
    outline: 2px solid #0B7EE9;
  }

  .profile--button.blue {
    background: #0B7EE9;
    outline: 2px solid #0B7EE9;
  }

  .profile--button.blue:hover {
    transition: 0.4s;
    color: #FFFFFF;
    outline: 2px solid #FFFFFF;
  }

  @media screen and (max-width: 700px) {

    .top-expert--block {
      margin-bottom: 70px;
    }

    .profile--block__buttons {
      margin: auto 20px;
      margin-top: 10px;
    }

  }

</style>