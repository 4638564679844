import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    base_url: process.env.VUE_APP_API_URL,
    expertsBotUrl: process.env.VUE_APP_EXPERTS_BOT_URL,
    topExperts: false,
    lastExperts: false,
    lastEvents: false,
    blogArticles: [],
    authorized: false,
    user: {}
  },
  mutations: {
    articles(state, data){
      state.userTransactions = data
    },
  },
  actions: {
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
    getArticles({ commit, state }) {
      axios.get(`${state.base_url}/api/blog/list/`, {}, {headers: {"Access-Control-Allow-Origin": "*"}})
      .then(response => {
          console.log(response);
        if (response.status === 200) {
          this.state.blogArticles = response.data
        }
        console.log(this.state.rightArticles)
      })
      .catch(error => {
        console.log(error)
      })
    },
    getExperts({ commit, state }) {
      console.log('state.base_url ', state.base_url)
      axios.get(`${state.base_url}/api/top-experts/`, {}, {headers: {"Access-Control-Allow-Origin": "*"}})
      .then(response => {
        if (response.status === 200) {
          this.state.topExperts = response.data.data;
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    getLastExperts({ commit, state }) {
      console.log('state.base_url ', state.base_url)
      axios.get(`${state.base_url}/api/finder/last-experts/`, {}, {headers: {"Access-Control-Allow-Origin": "*"}})
      .then(response => {
        if (response.status === 200) {
          this.state.lastExperts = response.data.data;
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    getEvents({ commit, state }) {
      console.log('state.base_url ', state.base_url)
      axios.get(`${state.base_url}/api/finder/last-events/`, {}, {headers: {"Access-Control-Allow-Origin": "*"}})
      .then(response => {
        if (response.status === 200) {
          this.state.lastEvents = response.data.data;
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    getArticle({ commit, state }, data) {
      axios.get(`${state.base_url}/api/blog/get/`, data, {headers: {"Access-Control-Allow-Origin": "*"}})
      .then(response => {
          console.log(response);
        if (response.status === 200) {
          console.log(response.data.data);
          console.log(response.data);
          let rights = [];
          for (var index in response.data) {
            let article = response.data[index];
            console.log(index, typeof(article))
            if (index == 0) this.state.leftArticle = article;
            else rights.push(article);
          }
          this.state.rightArticles = rights
        }
        console.log(this.state.rightArticles)
      })
      .catch(error => {
        console.log(error)
      })
    },
  },
  modules: {
  }
})
