<template>
  <div class="slide_sites--block">
    <div class="container content_mobile_style community_style--block style_content_mobile_blog">
      <a class="type_of_service blog_style_type_of_service" href="">SPHERE</a>
              <img class="charter_laptop_man" src="@/assets/images/arrow_blog.svg" alt="Стрелочка">
      <a class="type_of_service website--style blog_style_type_of_service" href="">Блог</a>
    </div>
  </div>

  <div class="services--block">
    <div class="container">
      <div class="content_information_blog--block">

        <div class="left_content_blog width_left_content_blog" v-for="article in blogArticles">
          <img class="girls_blog" :src="'https://sphere-experts.space' + article.photo" :alt="article.name" >
          <p class="left_content_blog--title">{{article.name}}</p>
          <p class="left_content_blog--text bottom_padding_left_content_blog" v-html="article.short_description" ></p>
          <p class="data_time--text">12.03.23</p>
          <a :href="'/article/' + article.id" class="read read_button_style">Читать</a>
          <div class="absolute_circle">
            <!-- <p class="absolute_circle-title lamp_absolute_circle">💡</p> -->
            <img class="absolute_circle-title lamp_absolute_circle" :src="'https://sphere-experts.space' + article.article_type.photo" :alt="article.article_type.name" />
          </div>
        </div>

        <!-- <div class="right_content_blog--block">
          <div class="lamp--block"> :style="background: article.article_type.photo_background;"
            <img :src="'https://sphere-experts.space' + article.article_type.photo" class="lamp--block--photo" :alt="article.article_type.name" />
          </div>
          <div class="right_blog--block">
            <h3 class="right_blog--title">{{article.name}}</h3>
            <h4 class="right_blog--text desktop_left_content_blog" v-html="article.short_description"></h4>
            <a :href="'/blog/' + article.id" class="read right_blog-style">Читать</a>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</template>


<script>
import { mapMutations,mapActions, mapState } from "vuex"; // mapGetters,

export default {
  name: 'Blog',
  components: {
  },
  computed:{
    ...mapState(["blogArticles"]),
  },
  data() {
    return {}
  },
  created() {
    this.getArticles();
  },
  methods: {
    ...mapActions(["getArticles"]),
  },
}
</script>


<style scoped>

  .absolute_circle {
    width: 50px;
    height: 50px;
  }

  .absolute_circle img {
    width: 100%;
    height: 100%;
  }

  .content_information_blog--block {
    width: 100%;
    flex-wrap: wrap;
  }
  
  .left_content_blog {
    width: 100%;
  }

</style>